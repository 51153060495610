var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container p-2"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('Курс валют(so\'m)'),"label-for":"dollar_rate"}},[_c('validation-provider',{attrs:{"name":"dollar_rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"dollar_rate","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"disabled":_vm.isShow,"placeholder":"0"},on:{"input":function($event){return _vm.changeDollarRate(_vm.form.dollar_rate)}},model:{value:(_vm.form.dollar_rate),callback:function ($$v) {_vm.$set(_vm.form, "dollar_rate", $$v)},expression:"form.dollar_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]),_c('table',{staticClass:"table table-bordered table-responsive"},[_c('thead',[_c('tr',[_c('th',[_vm._v("№")]),_c('th',[_vm._v("Номенклатура")]),_c('th',[_vm._v("Количиство")]),_c('th',[_vm._v("Цена(USD)")]),_c('th',[_vm._v("Цена(so'm)")]),_c('th',[_vm._v("Сумма")]),_c('th')])]),(_vm.form.products.length)?_c('tbody',_vm._l((_vm.form.products),function(product,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index+1))]),_c('td',{attrs:{"width":"400"}},[_c('b-form-group',{attrs:{"label-for":"category_id"}},[_c('validation-provider',{attrs:{"name":"category_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"category_id","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 3 ? false:null,"options":_vm.product_categories.data,"reduce":function (option) { return option.id; },"label":"name_uz"},model:{value:(product.category_id),callback:function ($$v) {_vm.$set(product, "category_id", $$v)},expression:"product.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('td',[_c('b-form-group',{attrs:{"label-for":"quantity"}},[_c('validation-provider',{attrs:{"name":"quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"id":"quantity","type":"number","state":errors.length > 3 ? false:null,"name":"quantity","placeholder":""},model:{value:(product.quantity),callback:function ($$v) {_vm.$set(product, "quantity", $$v)},expression:"product.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('td',[_c('b-form-group',{attrs:{"label-for":"cost_dollar"}},[_c('validation-provider',{attrs:{"name":"cost_dollar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cost_dollar","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"placeholder":"0"},on:{"input":function($event){return _vm.calcProductSum(product.cost_dollar, index)}},model:{value:(product.cost_dollar),callback:function ($$v) {_vm.$set(product, "cost_dollar", $$v)},expression:"product.cost_dollar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('td',[_c('b-form-group',{attrs:{"label-for":"cost"}},[_c('validation-provider',{attrs:{"name":"cost","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cost","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"placeholder":"0"},model:{value:(product.cost),callback:function ($$v) {_vm.$set(product, "cost", $$v)},expression:"product.cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatPrice")(product.quantity*product.cost))+" so'm ")]),_c('td',[(_vm.form.products.length!=1)?_c('feather-icon',{attrs:{"color":"#FF0000","icon":"TrashIcon"},on:{"click":function($event){return _vm.deleteProduct(index)}}}):_vm._e()],1)])}),0):_vm._e()]),_c('b-button',{staticClass:"float-left mt-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.addProduct()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Добавить ")],1),_c('b-button',{staticClass:"mt-5 float-right",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Сохранить ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }