<template>
  <b-modal
    id="modal-lg"
    v-model="visible"
    cancel-variant="outline-secondary"
    size="xl"
    hide-footer
    no-close-on-backdrop
    centered
    @close="close"
  >
    <b-table
      striped
      hover
      :items="items.row"
      :fields="fields"
    >
      <template #cell(NUM)="data">
        {{ data.index+1 }}
      </template>
      <template #cell(summa)="data">
        {{ data.item.summa|formatPrice }} so'm
      </template>
    </b-table>
  </b-modal>
</template>

<script>
export default {
  props: {
    items: {
      type: [Array, Object],
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'NUM',
          label: '№',
        },
        {
          key: 'period',
          label: 'Год и месяц начисления',
          sortable: true,
        },
        {
          key: 'month',
          label: 'Месяц',
        },
        {
          key: 'summa',
          label: 'Сумма',
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close', true)
    },
  },

}
</script>
