<template>
  <b-modal
    id="modal-lg"
    v-model="visible"
    cancel-variant="outline-secondary"
    size="xl"
    hide-footer
    no-close-on-backdrop
    centered
    @close="close"
  >
    <h3 class="text-center">
      Кредитный отчёт "СКОРИНГ КИАЦ"
    </h3>
    <table
      v-if="report"
      class="table table-bordered"
    >
      <thead>
        <tr>
          <th
            rowspan="2"
            scope="col"
          >
            №
          </th>
          <th
            scope="col"
            rowspan="2"
          >
            Наименование
          </th>
          <th
            scope="col"
            colspan="2"
          >
            Открытые
          </th>
          <th
            scope="col"
            colspan="2"
          >
            Закрытые
          </th>
        </tr>
        <tr>
          <th scope="col">
            Количество
          </th>
          <th scope="col">
            Сумма
          </th>
          <th scope="col">
            Количество
          </th>
          <th scope="col">
            Сумма
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            1
          </th>
          <th scope="row">
            Все виды задолженности
          </th>
          <td>{{ report.current_debt.all_delays_qty }}</td>
          <td>{{ report.current_debt.all_delays_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.all_delays_qty }}</td>
          <td>{{ report.closed_debt.all_delays_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            2
          </th>
          <th scope="row">
            Договора без единой просрочки
          </th>
          <td>{{ report.current_debt.contracts_wo_delays_qty }}</td>
          <td>{{ report.current_debt.contracts_wo_delays_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.contracts_wo_delays_qty }}</td>
          <td>{{ report.closed_debt.contracts_wo_delays_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            3
          </th>
          <th scope="row">
            Просроченные проценты
          </th>
          <td>{{ report.current_debt.expired_percent_qty }}</td>
          <td>{{ report.current_debt.expired_percent_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.expired_percent_qty }}</td>
          <td>{{ report.closed_debt.expired_percent_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            4
          </th>
          <th scope="row">
            Пересмотренные
          </th>
          <td>{{ report.current_debt.revised_contracts_qty }}</td>
          <td>{{ report.current_debt.revised_contracts_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.revised_contracts_qty }}</td>
          <td>{{ report.closed_debt.revised_contracts_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            5
          </th>
          <th scope="row">
            Просрочки до 30 дней
          </th>
          <td>{{ report.current_debt.delays_less_30_qty }}</td>
          <td>{{ report.current_debt.delays_less_30_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.delays_less_30_qty }}</td>
          <td>{{ report.closed_debt.delays_less_30_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            6
          </th>
          <th scope="row">
            Просрочки, переходящие на следующий месяц
          </th>
          <td>{{ report.current_debt.switched_month_qty }}</td>
          <td>{{ report.current_debt.switched_month_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.switched_month_qty }}</td>
          <td>{{ report.closed_debt.switched_month_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            7
          </th>
          <th scope="row">
            Просрочки от 30 до 60 дней
          </th>
          <td>{{ report.current_debt.delays_less_60_qty }}</td>
          <td>{{ report.current_debt.delays_less_60_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.delays_less_60_qty }}</td>
          <td>{{ report.closed_debt.delays_less_60_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            8
          </th>
          <th scope="row">
            Просрочки от 60 до 90 дней
          </th>
          <td>{{ report.current_debt.delays_less_90_qty }}</td>
          <td>{{ report.current_debt.delays_less_90_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.delays_less_90_qty }}</td>
          <td>{{ report.closed_debt.delays_less_90_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            9
          </th>
          <th scope="row">
            Просрочки от 90 дней и более
          </th>
          <td>{{ report.current_debt.delays_more_90_qty }}</td>
          <td>{{ report.current_debt.delays_more_90_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.delays_more_90_qty }}</td>
          <td>{{ report.closed_debt.delays_more_90_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            10
          </th>
          <th scope="row">
            Судебные
          </th>
          <td>{{ report.current_debt.delays_in_court_qty }}</td>
          <td>{{ report.current_debt.delays_in_court_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.delays_in_court_qty }}</td>
          <td>{{ report.closed_debt.delays_in_court_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            11
          </th>
          <th scope="row">
            Списанные по основным долгам
          </th>
          <td>{{ report.current_debt.delays_decommissioned_qty }}</td>
          <td>{{ report.current_debt.delays_decommissioned_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.delays_decommissioned_qty }}</td>
          <td>{{ report.closed_debt.delays_decommissioned_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            12
          </th>
          <th scope="row">
            Внебалансовые задолженности по процентам
          </th>
          <td>{{ report.current_debt.delays_decommissioned_pcnt_qty }}</td>
          <td>{{ report.current_debt.delays_decommissioned_pcnt_sum |formatPrice }}</td>

          <td>{{ report.closed_debt.delays_decommissioned_pcnt_qty }}</td>
          <td>{{ report.closed_debt.delays_decommissioned_pcnt_sum |formatPrice }}</td>
        </tr>
        <tr>
          <th scope="row">
            13
          </th>
          <th scope="row">
            Оповещение
          </th>
          <td colspan="4">
            <template v-if="typeof report.notifications.notification == 'object'">
              <div
                v-for="(notification, index) in report.notifications.notification"
                :key="index"
              >
                - {{ notification }}
              </div>
            </template>
            <template v-else>
              {{ report.notifications.notification }}
            </template>
          </td>
        </tr>

      </tbody>
    </table>
  </b-modal>
</template>

<script>
export default {
  props: {
    report: {
      type: [Array, Object],
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  methods: {
    close() {
      this.$emit('close', true)
    },
  },

}
</script>
