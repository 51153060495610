<template>
  <div>
    <b-modal
      v-model="icloudeModal"
      size="lg"
      centered
      hide-footer
      title="Qurilmani tizimga biriktirish"
      no-close-on-backdrop
      @close="$emit('close', true)"
      @cancel="$emit('close', true)"
    >
      <b-row v-if="!formType">
        <b-col
          md="6"
          lg="6"
        >
          <b-card
            title="Samsung uchun"
            class="mb-3 text-center card-bordered"
          >
            <b-overlay :show="loadingKnoxBtn">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="w-100"
                variant="outline-primary"
                @click="attachKnox"
              >
                Biriktirish
              </b-button>
            </b-overlay>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="6"
        >
          <b-card
            title="Iphone uchun"
            class="text-center card-bordered"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="w-100"
              variant="outline-primary"
              @click="formType = 2"
            >
              Tanlash
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-form
        v-else-if="formType == 1"
        class="auth-login-form mt-2"
      >
        <b-form-group
          label="IMEI'ni tanlang"
          label-for="tag"
        >
          <v-select
            id="tag"
            v-model="IMEI_objs"
            placeholder="IMEI tanlang"
            :options="IMEIList"
            label="type"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              <span>
                {{ "IMEI " + option.type + "(" + option.label + ")" }}
              </span>
            </template>
            <span
              slot="no-options"
              @click="$refs.vSelect.open = false"
            >
              Kechirasiz, IMEI topilmadi
            </span>
          </v-select>
        </b-form-group>

        <i>Qurilma IMEI'laridan birni tanlash orqali KNOXga shu IMEI
          biriktiriladi. Har ikklasini tanlaganda ham qurilmani bloklash
          mumkin</i>
      </b-form>
      <b-form
        v-else-if="formType"
        class="auth-login-form mt-2"
      >
        <b-form-group
          label="iCloudni tanlang"
          label-for="tag"
        >
          <v-select
            id="tag"
            v-model="iCloud_objs"
            placeholder="iCloudni tanlang"
            :options="iCloudsList"
            :get-option-label="
              (option) =>
                option.lastname +
                ' ' +
                option.firstname +
                ' (' +
                option.mail +
                ')'
            "
          >
            <template
              slot="option"
              slot-scope="option"
            >
              <span>
                {{
                  option.lastname + " " + option.firstname + " - " + option.mail
                }}
              </span>
            </template>
            <span
              slot="no-options"
              @click="$refs.vSelect.open = false"
            >
              Kechirasiz, iCloud topilmadi <br>
              <!-- <router-link :to="{name:'SettingsProductCategory'}"> -->
              <span
                class="link"
                @click="$router.push({ name: 'iCloudList' })"
              >
                <feather-icon icon="PlusIcon" />
                iCloud qo'shish
              </span>
              <!-- </router-link> -->
            </span>
          </v-select>
        </b-form-group>
      </b-form>
      <div class="d-flex justify-content-end">
        <b-overlay :show="loadingBtn">
          <b-button
            variant="primary"
            class="float-right"
            size="small"
            @click="onAttach"
          >
            <feather-icon icon="SendIcon" />
            Biriktirish
          </b-button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    contractProductId: {
      type: Number,
      default: null,
    },
    productInfo: {
      type: Object,
      default: null,
    },
    notAttach: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icloudeModal: true,
      iCloud_objs: null,
      iCloudsList: [],
      IMEIList: [],
      IMEI_objs: null,
      openForm: false,
      formType: null,
      loadingBtn: false,
      loadingKnoxBtn: false,
      attachForm: {
        which_imei: 1,
        contract_product_id: null,
        icloud_id: null,
        imei: null,
      },
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    isHasNotUploaded() {
      return this.imageData.photos.some(p => p.url == '')
    },
  },
  mounted() {
    this.attachForm.contract_product_id = this.contractProductId
    if (this.productInfo.imei_1) {
      this.IMEIList = [
        { label: this.productInfo.imei_1, type: 1 },
        { label: this.productInfo.imei_2, type: 2 },
      ]
    }
    this.getICloudItemsAction({ per_page: 9999, is_attached: 0 }).then(
      res => {
        this.iCloudsList = res.data.data
      },
    )
  },
  methods: {
    ...mapActions({
      fileUpload: 'client/fileUpload',
      getICloudItemsAction: 'resource/getIClouds',
      attachICloud: 'resource/attachICloud',
      storeKnox: 'resource/storeKnox',
    }),
    attachKnox() {
      if (this.productInfo.imei_2) {
        this.formType = 1
        this.IMEI_objs = this.IMEIList[0]
      } else {
        this.attachForm.imei = this.productInfo.imei
        if (!this.productInfo.imei) {
          showToast('danger', this.$t('Ushbu mahsulotda IMEI biriktirilmagan'))
        } else {
          console.log(1, this.attachForm)
          this.loadingKnoxBtn = true
          this.storeKnox(this.attachForm).then(res => {
            if (res.success) {
              showToast('success', this.$t('Muvaffaqiyatli biriktirildi'))
              this.$emit('close', res.data)
            }
          }).finally(() => {
            this.loadingKnoxBtn = false
          })
        }
      }
    },
    attachKnoxAction() {
      if (this.IMEI_objs) {
        this.loadingBtn = true
        this.attachForm.imei = this.IMEI_objs.label
        this.attachForm.which_imei = this.IMEI_objs.type
        console.log(2, this.attachForm)
        this.storeKnox(this.attachForm)
          .then(res => {
            if (res.success) {
              showToast('success', this.$t('Muvaffaqiyatli biriktirildi'))
              this.$emit('close', res.data)
            }
          })
          .finally(() => {
            this.loadingBtn = false
          })
      } else {
        showToast('danger', this.$t('IMEI tanlang'))
      }
    },
    onAttach() {
      if (this.formType == 1) {
        this.attachKnoxAction()
      } else {
        this.attachIcloud()
      }
    },
    attachIcloud() {
      this.attachForm.icloud_id = this.iCloud_objs.id
      if (this.notAttach) {
        showToast('success', this.$t('Muvaffaqiyatli biriktirildi'))
        this.$emit('close', this.iCloud_objs)
      } else {
        this.loadingBtn = true
        this.attachICloud(this.attachForm).then(res => {
          if (res.success) {
            showToast('success', this.$t('Muvaffaqiyatli biriktirildi'))
            this.$emit('close', res.data)
          }
        }).finally(() => {
          this.loadingBtn = false
        })
      }
    },
  },
}
</script>

<style></style>
