<template>
  <div>
    <template v-if="!is_sent_sms">
      <validation-observer
        ref="form"
        #default="{invalid}"
      >
        <b-form
          v-if="!loading"
          class="auth-login-form mt-2"
          @submit.prevent="submit"
        >
          <b-form-group
            :label="$t('Написать причина')"
            label-for="body"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Написать причина')"
              rules="required"
            >
              <b-form-textarea
                id="body"
                v-model="form.body"
                class="form-control"
                :state="errors.length > 0 ? false:null"
                placeholder="Причина отказа"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="mt-2 text-right">
            <b-button
              :disabled="loading"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Подтверждать
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
    <!--  -->
    <template v-if="is_sent_sms && !form.is_sms_confirmed">
      <b-col cols="12">
        <b-form-group
          :label="$t('Введите код из СМС')"
          label-for="sms"
        >
          <b-form-input
            id="sms"
            v-model="form.code"
            v-mask="'####'"
            type="number"
            placeholder="0000"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-button
          v-if="form.code && form.code.length > 3"
          type="submit"
          variant="primary"
          class="mt-2"
          @click="checkSms"
        >
          {{ $t("Tasdiqlash") }}
        </b-button>
      </b-col>
    </template>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      form: {
        body: null,
        phone:null,
        code: null,
        is_sms_confirmed: false,
      },
      is_sent_sms: false,
      loading: false,
      comments: null,
      required,
    }
  },
  computed: {
    ...mapGetters({ settings: 'setting/GET_ITEMS' }),
  },
  mounted() {
    this.fetchSettings().then(() => {
      const adminPhone = this.settings.data.find(c => c.code == 'exception_situations_confirm_phone')
      this.form.phone = adminPhone.value
    })
  },
  methods: {
    ...mapActions({
      confirmSms: 'resource/confirmSms',
      sendSmsAction: 'resource/sendSms',
      fetchSettings: 'setting/index',
    }),
    sendSms() {
      this.sendSmsAction({ phone: this.form.phone }).then(() => {
        this.is_sent_sms = true
      })
    },
    checkSms() {
      this.confirmSms({ phone: this.form.phone, code: this.form.code })
        .then(res => {
          if (res.success) {
            this.form.is_sms_confirmed = true
            this.$emit('submit', this.form.body)
          }
        })
        .catch(err => {
          showToast('danger', err.data.message, 'XIcon')
        })
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    async submit() {
      const valid = await this.validationForm()
      if (valid) {
        this.loading = true
        this.sendSms()
        this.loading = false
      }
    },
  },
}
</script>

<style>

</style>
