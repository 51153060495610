<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Проверить')"
    no-close-on-backdrop
    size="lg"
    :cancel-title="$t('Отмена')"
    centered
    :title="$t('Выберите тип клиента')"
    @close="close"
    @cancel="close"
    @ok.prevent="save"
  >
    <b-card
      v-if="loading"
      class="text-center"
      style="padding:200px"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem;"
        :class="loading?'double-overlay':''"
      />
    </b-card>
    <template v-else>
      <div class="d-flex flex-row justify-content-center">
        <b-card
          v-for="(item, index) in list"
          :key="index"
          class="c-pointer m-1 w200 text-center"
          :class="{'active-card':item.id==form.report_strategy_id}"
          @click="getClientType(item.id)"
        >
          <img
            :src="require(`@/assets/images/icons/strategy/${item.id}.png`)"
            class="img-placeholder"
            alt="image"
            width="100"
          >
          <p class="text-primary font-weight-bolder ">
            {{ item['name_'+$i18n.locale] }}
          </p>
        </b-card>
      </div>
      <div
        v-if="form.report_strategy_id == 2"
        class="m-auto"
      >
        <b-col cols="4">
          <b-form-group
            :label="$t('Kartaning oxirgi 4 rqamini kiriting')"
            label-for="v-pin"
          >
            <b-form-input
              id="v-pin"
              v-model="form.card_number4"
              v-mask="'####'"
              placeholder="************0000"
            />
          </b-form-group>
        </b-col>
      </div>

      <template v-if="form.co_pin">
        <h5>{{ $t('Выберите тип созаемщика') }}:</h5>
        <div class="d-flex flex-row justify-content-center">
          <b-card
            v-for="(item, index) in list"
            :key="index"
            class="c-pointer m-1 w200 text-center"
            :class="{'active-card':item.id==form.report2_strategy_id}"
            @click="getClient2Type(item.id)"
          >
            <img
              :src="require(`@/assets/images/icons/strategy/${item.id}.png`)"
              class="img-placeholder"
              alt="image"
              width="100"
            >
            <p class="text-primary font-weight-bolder ">
              {{ item['name_'+$i18n.locale] }}
            </p>
          </b-card>
        </div>
        <div
          v-if="form.report2_strategy_id == 2"
          class="m-auto"
        >
          <b-col cols="4">
            <b-form-group
              :label="$t('Kartaning oxirgi 4 rqamini kiriting')"
              label-for="v-pin"
            >
              <b-form-input
                id="v-pin"
                v-model="form.card2_number4"
                v-mask="'####'"
                placeholder="************0000"
              />
            </b-form-group>
          </b-col>
        </div>
      </template>
    </template>
  </b-modal>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'

export default {
  name: 'Create',

  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      loading: false,
      selected: null,
      title: '',
      visible: false,
      selectedId: null,
      selected2Id: null,
      list: [],
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.getItems()
    this.visible = true

    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    save() {
      if (this.form.report_strategy_id == 2) {
        if (this.form.card_number4 && this.form.card_number4.length > 3) {
          this.$emit('save', true)
          this.close()
        } else {
          showToast('danger', 'Kartaning oxirgi 4 rqamini kiriting')
        }
      } else if (this.form.report2_strategy_id == 2) {
        if (this.form.card2_number4 && this.form.card2_number4.length > 3) {
          this.$emit('save', true)
          this.close()
        } else {
          showToast('danger', 'Kartaning oxirgi 4 rqamini kiriting')
        }
      } else {
        this.$emit('save', true)
        this.close()
      }
    },
    select(item, isSelect) {
      this.form.category_id = item.id
      this.selected = isSelect ? item : null
    },
    close() {
      this.visible = false
      this.$emit('close', true)
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction()
        .then(res => {
          this.list = res.data
        })
      this.loading = false
    },
    getClientType(id) {
      this.form.report_strategy_id = id
    },
    getClient2Type(id) {
      this.form.report2_strategy_id = id
    },
    ...mapActions({
      getItemsAction: 'resource/reportStrategyList',
    }),
  },
}
</script>

<style scoped>

</style>
