<template>
  <b-modal
    v-model="visible"
    centered
    hide-footer
    :title="$t('Выберите тип продукт')"
    no-close-on-backdrop
    @close="close"
    @cancel="close"
  >
    <div>
      {{ form }}
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="save"
      >
        <b-row>
          <b-col cols="8">
            <b-form-group
              :label="$t('Выберите продукт')"
              label-for="product_id"
            >
              <v-select
                id="category_id"
                v-model="form.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="products"
                :reduce="(option) => option.category_id"
                :get-option-label="option => (option.category.name_uz+'('+option.quantity+')')"
                @input="setSelectedProduct"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              :label="$t('Число')"
              label-for="product_count"
            >

              <b-form-input
                id="product_count"
                v-model="form.count"
                v-mask="'####'"
                type="number"
                name="credit_months"
                placeholder="1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <p v-if="selected_product">
          <b>{{ selected_product.category.name_uz }}</b>
          <!-- - {{ selected_product }} -->
        </p>
        <!--  -->
        <div
          class="text-right mt-3"
        >
          <b-button
            variant="primary"
            class="btn-tour-skip"
            @click="save"
          >
            <span class="mr-25 align-middle">Hisoblash</span>
          </b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions, mapGetters } from 'vuex'
import VTree from 'vue-tree-halower'
import 'vue-tree-halower/dist/halower-tree.min.css'
import vSelect from 'vue-select'

export default {
  name: 'Create',
  components: { VTree, vSelect },
  props: {
    updateProductData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      loading: false,
      selected: null,
      title: '',
      visible: false,
      selected_product: null,
      form: {
        id: null, category_id: null, price: null, count: 1,
      },
    }
  },
  computed: {
    ...mapGetters({ products: 'productCategory/GET_WAREHOUSE_PRODUCTS' }),
  },
  watch: {
  },
  mounted() {
    this.visible = true
    this.getItems()
    if (this.updateProductData) {
      this.form = { ...this.updateProductData }
    }
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    save() {
      if (this.updateProductData) {
        this.$emit('updateProduct', this.form)
      } else {
        this.$emit('addProduct', this.form)
      }
      this.close()
    },
    setProductPrice(id) {
      const obj = this.products.data.find(el => el.id == id)
      this.form.name = obj.name_uz
      this.form.price = obj.price
    },
    close() {
      this.visible = false
      this.changeQuery('update_product', null)
      this.$emit('close', true)
    },
    async getItems() {
      this.loading = true
      await this.getProductsAction()
      this.loading = false
    },
    setSelectedProduct(product) {
      this.selected_product = this.products.find(el => el.category_id == product)
    },
    ...mapActions({
      getProductsAction: 'productCategory/warehouseProducts',
    }),
  },
}
</script>

<style scoped>

</style>
