<template>
  <div>
    <div class="">
      <!-- Клиент Info -->
      <div v-if="contract.client && $route.name == 'OrderCreate'" class="p-2">
        <p>
          <b>FIO</b>:
          {{
            [
              contract.client.lastname,
              contract.client.firstname,
              contract.client.middlename,
            ].join(" ")
          }}
        </p>
        <p><b>JShShIR</b>: {{ contract.client.pin }}</p>
        <p><b>Telefon</b>: {{ contract.client.phone }}</p>
        <p><b>Adres</b>: {{ contract.client.address }}</p>
      </div>
      <!-- <h3>Информация о контракте</h3> -->
      <div class="card p-2">
        <div
          v-if="
            contract.visible_products &&
            contract.visible_products.length &&
            contract.visible_products[0].contract_id
          "
          class="mb-2"
        >
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th>Nomi</th>
                <th>Soni</th>
                <th>Narxi</th>
                <th>Jami</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(product, index) in contract.visible_products">
                <tr :key="index">
                  <td>
                    {{ product.category ? product.category.name_uz : "" }}
                  </td>
                  <td>{{ product.product_quantity }} dona</td>
                  <!-- <td>dona</td> -->
                  <td>{{ product.product_price | formatPrice }}so'm</td>
                  <td>
                    {{ product.product_price_with_percent | formatPrice }}so'm
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="2" class="text-center">Детали контракта</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Срок рассрочки (месяц)</th>
              <td>{{ contract.credit_months }}</td>
            </tr>
            <tr>
              <th>Cумма предоплаты</th>
              <td>
                {{ contract.in_advance_payment_amount | formatPrice }} so'm
              </td>
            </tr>
            <tr v-if="contract.discount_amount">
              <th>Cумма скидка</th>
              <td>{{ contract.discount_amount | formatPrice }} so'm</td>
            </tr>
            <tr>
              <th>Сумма рассрочки</th>
              <td>{{ contract.credit_amount | formatPrice }} so'm</td>
            </tr>
            <tr>
              <th>День начала</th>
              <td>{{ dateDotFormat(contract.start_date) }}</td>
            </tr>
            <tr>
              <th>День окончания</th>
              <td>{{ dateDotFormat(contract.end_date) }}</td>
            </tr>
            <tr>
              <th>Контакты близких</th>
              <td>
                <span v-if="contract.near_phone1">
                  +998{{ contract.near_phone1 }}
                </span>
                <span v-if="contract.near_phone2">
                  , +998{{ contract.near_phone2 }}
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-center">
                <b-button
                  v-if="contract.status != 2 && contract.is_rejected == 0"
                  variant="outline-danger"
                  class="mr-1"
                  @click="reject_modal = true"
                >
                  Shartnomani bekor qilish
                </b-button>
                <b-button
                  v-else-if="contract.is_rejected == 1"
                  variant="danger"
                  class="mr-1"
                  disabled
                >
                  Shartnoma bekor qilingan
                </b-button>
                <b-button
                  v-if="contract.status == 2"
                  variant="warning"
                  disabled
                  class="mr-1"
                >
                  Shartnoma yopilgan
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="reject_modal">
      <b-modal
        v-model="reject_modal"
        size="md"
        centered
        hide-footer
        no-close-on-backdrop
        title="Shartnomani bekor qilish"
        @close="reject_modal = false"
        @cancel="reject_modal = false"
      >
        <RejectReason @submit="reject" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RejectReason from "@/components/modals/rejectReason.vue";
import { showToast } from "@/utils/toast";

export default {
  components: {
    RejectReason,
  },
  props: {
    orderId: {
      type: [Number, String],
      default: () => null,
    },
  },
  data() {
    return {
      reject_modal: false,
    };
  },
  computed: {
    ...mapGetters({
      contract: "contract/GET_ITEM",
    }),
  },
  watch: {},
  async mounted() {
    this.getAction();
  },
  methods: {
    ...mapActions({
      getItemAction: "contract/show",
      rejectContract: "contract/rejectContract",
    }),
    reject(reason) {
      const data = {
        contract_id: this.$route.query.order_id,
        rejection_reason: reason,
      };
      this.rejectContract(data).then((res) => {
        if (res.success) {
          showToast("success", this.$t("Успешно оформлен"));
          this.$router.push({ name: "OrdersIndex" });
        }
      });
    },
    getAction() {
      this.getItemAction({
        id: this.orderId,
        relations: "client|client2|payments|visibleProducts.category",
      }).then((resp) => {
        if (resp.success) {
          this.payment_list = resp.data.payments;
        }
      });
    },
  },
};
</script>

<style></style>
