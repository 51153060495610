<template>
  <b-modal
    id="modal-lg"
    v-model="visible"
    cancel-variant="outline-secondary"
    size="xl"
    hide-footer
    no-close-on-backdrop
    centered
    @close="close"
  >
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
    >
      <template #cell(INCOME_SUMMA)="data">
        {{ (data.item.INCOME_SUMMA?data.item.INCOME_SUMMA:data.item.income_summa)|formatPrice }}
      </template>
      <template #cell(INPS_SUMMA)="data">
        {{ (data.item.INPS_SUMMA?data.item.INPS_SUMMA:data.item.inps_summa)|formatPrice }}
      </template>

      <template #cell(PERIOD)="data">
        {{ data.item.PERIOD?data.item.PERIOD:data.item.period }}
      </template>
      <template #cell(ORGNAME)="data">
        {{ data.item.ORGNAME?data.item.ORGNAME:data.item.orgname }}
      </template>
      <template #cell(ORG_INN)="data">
        {{ data.item.ORG_INN?data.item.ORG_INN:data.item.org_inn }}
      </template>
      <template #cell(SEND_DATE)="data">
        {{ data.item.SEND_DATE?data.item.SEND_DATE:data.item.send_date }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'NUM',
          label: '№',
        },
        {
          key: 'PERIOD',
          label: 'Год и месяц начисления',
          sortable: true,
        },
        {
          key: 'ORGNAME',
          label: 'Организация',
        },
        {
          key: 'ORG_INN',
          label: 'ИНН организации',
        },
        {
          key: 'SEND_DATE',
          label: 'Дата обновления',
        },
        {
          key: 'INPS_SUMMA',
          label: 'Сумма ИНПС',
        },
        {
          key: 'INCOME_SUMMA',
          label: 'Сумма дохода',
          sortable: true,
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close', true)
      this.visible = false
    },
  },

}
</script>
