<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    centered
    :title="$t('Отказать')"
    @close="$emit('close', true)"
    @cancel="$emit('close', true)"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <b-form-group
          :label="$t('Причина отказа')"
          label-for="images_deny_reason"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Причина отказа')"
            rules="required"
          >
            <b-form-textarea
              id="images_deny_reason"
              v-model="form.images_deny_reason"
              :state="errors.length > 3 ? false:null"
              :name="$t('Причина отказа')"
              rows="3"
              max-rows="6"
              :placeholder="$t('Причина отказа')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver,
  },
  props: {
    visible: {
      type: Boolean, default: false,
    },
  },
  data() {
    return {
      form: {
        images_deny_reason: null,
      },
      title: '',
      required,
    }
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {

  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.$emit('rejected', this.form)
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },

    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      storeItem: 'role/store',
      updateItem: 'role/update',
    }),
  },
}
</script>

<style scoped>

</style>
