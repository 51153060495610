<template>
  <b-modal
    id="modal-lg"
    v-model="visible"
    cancel-variant="outline-secondary"
    size="xl"
    hide-footer
    no-close-on-backdrop
    centered
    @close="close"
  >
    <b-table
      striped
      hover
      :items="orderedItems"
      :fields="fields"
    >
      <template #cell(salary_tax_sum)="data">
        {{ data.item.salary_tax_sum|formatPrice }}
      </template>
      <template #cell(salary_full_sum)="data">
        {{ (data.item.salary - data.item.salary_tax_sum)|formatPrice }}
      </template>
      <template #cell(inps_sum)="data">
        {{ data.item.inps_sum|formatPrice }}
      </template>
      <template #cell(period)="data">
        {{ (data.item.period ? data.item.period.toString().padStart(2, '0') : '-') +'.'+ data.item.year }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'NUM',
          label: '№',
        },
        {
          key: 'period',
          label: 'Год и месяц начисления',
          sortable: true,
        },
        {
          key: 'company_name',
          label: 'Организация',
        },
        {
          key: 'company_tin',
          label: 'ИНН организации',
        },
        // {
        //   key: 'SEND_DATE',
        //   label: 'Дата обновления',
        // },
        {
          key: 'other_income',
          label: 'Дополнительный заработок',
          sortable: true,
        },
        {
          key: 'inps_sum',
          label: 'Сумма ИНПС',
        },
        {
          key: 'salary',
          label: 'Зарплата',
          sortable: true,
        },
        {
          key: 'salary_tax_sum',
          label: 'Сумма налога',
          sortable: true,
        },
        {
          key: 'salary_full_sum',
          label: 'Сумма дохода',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    orderedItems() {
      let array = []
      if (this.items && this.items.length) {
        this.items.map(item => {
          item.custom_date = `${item.year}-${item.period ? item.period.toString().padStart(2, '0') : '-'}`
          return item
        })
        array = this.items.sort((a, b) => (a.custom_date < b.custom_date ? 1 : -1)).slice(0, 12)
      }
      return array
    },
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close', true)
      this.visible = false
    },
  },

}
</script>
