<template>
  <div class="container p-2">
    <h1>{{ title }}</h1>
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="save"
      >
        <div class="row">
          <div class="col-3">
            <b-form-group
              :label="$t('Курс валют(so\'m)')"
              label-for="dollar_rate"
            >
              <validation-provider
                #default="{ errors }"
                name="dollar_rate"
                rules="required"
              >
                <cleave
                  id="dollar_rate"
                  v-model="form.dollar_rate"
                  class="form-control"
                  :state="errors.length > 0 ? false:null"
                  :options="cleaveOption"
                  :disabled="isShow"
                  placeholder="0"
                  @input="changeDollarRate(form.dollar_rate)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <!-- Products -->
        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th>№</th>
              <th>Номенклатура</th>
              <th>Количиство</th>
              <th>Цена(USD)</th>
              <th>Цена(so'm)</th>
              <th>Сумма</th>
              <th />
            </tr>
          </thead>
          <tbody v-if="form.products.length">
            <tr
              v-for="(product, index) in form.products"
              :key="index"
            >
              <td>{{ index+1 }}</td>
              <td width="400">
                <b-form-group
                  label-for="category_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="category_id"
                    rules="required"
                  >
                    <v-select
                      id="category_id"
                      v-model="product.category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :state="errors.length > 3 ? false:null"
                      :options="product_categories.data"
                      :reduce="(option) => option.id"
                      label="name_uz"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </td>
              <td>
                <b-form-group
                  label-for="quantity"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="quantity"
                    rules="required"
                  >
                    <b-form-input
                      id="quantity"
                      v-model="product.quantity"
                      v-mask="'#####'"
                      type="number"
                      :state="errors.length > 3 ? false:null"
                      name="quantity"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </td>
              <td>
                <b-form-group
                  label-for="cost_dollar"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cost_dollar"
                    rules="required"
                  >
                    <cleave
                      id="cost_dollar"
                      v-model="product.cost_dollar"
                      class="form-control"
                      :state="errors.length > 0 ? false:null"
                      :options="cleaveOption"
                      placeholder="0"
                      @input="calcProductSum(product.cost_dollar, index)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </td>
              <td>
                <b-form-group
                  label-for="cost"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cost"
                    rules="required"
                  >
                    <cleave
                      id="cost"
                      v-model="product.cost"
                      class="form-control"
                      :state="errors.length > 0 ? false:null"
                      :options="cleaveOption"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </td>
              <td>
                {{ product.quantity*product.cost |formatPrice }} so'm
              </td>
              <td>
                <feather-icon
                  v-if="form.products.length!=1"
                  color="#FF0000"
                  icon="TrashIcon"
                  @click="deleteProduct(index)"
                />
              </td>

            </tr>
          </tbody>
        </table>
        <b-button
          class="float-left mt-2"
          variant="outline-primary"
          @click="addProduct()"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить
        </b-button>

        <b-button
          type="submit"
          class="mt-5 float-right"
          variant="primary"
        >
          Сохранить
        </b-button>

        <!-- <b-button
          v-if="isShow"
          class="mt-1 float-left"
          variant="primary"
          @click="makeNakladnoy"
        >
          <feather-icon
            icon="ClipboardIcon"
            size="16"
          />
          Накладной
        </b-button> -->
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, vSelect, Cleave,
  },
  props: {
  },
  data() {
    return {
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      reject_modal: false,
      rejectedProducts: [],
      form: {
        id: null,
        provider_id: null,
        date: null,
        comment: null,
        dollar_rate: null,
        products: [
          {
            category_id: null, quantity: 1, cost: null, cost_dollar: null, dollar_rate: null,
          },
        ],
      },
      filterProduct: {
        level: 3,
        per_page: 9999,
        name_uz: '',
      },
      product_barcode: '',
      show_print_barcode: false,
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      cities: 'city/GET_ITEMS',
      partiesInfo: 'parties/GET_ITEM',
      product_categories: 'productCategory/GET_ITEMS',
    }),
    itemId() {
      return this.$route.params.id
    },
    isShow() {
      return !!(this.$route.name === 'warehouseParty-show')
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  watch: {
    $route(newVal) {
      if (newVal) {
        this.showAction()
      }
    },
  },
  mounted() {
    this.title = this.$t('Поступление товаров')
    this.fetchProducts()
    if (this.isShow) {
      this.showAction()
    }
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.form.products.forEach(el => {
          el.dollar_rate = this.form.dollar_rate
        })
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('save', true)
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    showPrint(barcode) {
      this.product_barcode = barcode
      this.show_print_barcode = true
    },
    calcProductSum(costUsd, index) {
      this.form.products[index].cost = Number(costUsd) * Number(this.form.dollar_rate)
    },
    changeDollarRate(rate) {
      if (this.form.products[0].cost_dollar) {
        this.form.products.forEach(el => {
          el.cost = Number(el.cost_dollar) * Number(rate)
        })
      }
    },
    method(data) {
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    fetchProducts() {
      this.getCategories(this.filterProduct)
    },
    addProduct() {
      this.form.products.push(
        {
          category_id: null, quantity: 1, cost: null, cost_dollar: null, dollar_rate: null,
        },
      )
    },
    showAction() {
      this.showItem(this.itemId).then(res => {
        this.rejectedProducts = res.data.rejected_products
        this.form.id = this.partiesInfo.id
        this.form.provider_id = this.partiesInfo.provider_id
        this.form.date = this.partiesInfo.date
        this.form.comment = this.partiesInfo.comment
        this.form.products = this.partiesInfo.visible_products.map(product => ({
          category_id: product.category_id,
          quantity: product.quantity,
          cost: product.cost,
          barcode: product.barcode,
        }))
      })
    },
    deleteProduct(index) {
      this.form.products.splice(index, 1)
    },
    makeNakladnoy() {
      this.makeNakladnoyPdf(this.$route.params.id).then(res => {
        window.open(`${`${this.storageUrl}/nakladnoys/${res.data}`}.pdf`, '_blank')
      })
    },
    ...mapActions({
      getCategories: 'productCategory/index',
      storeItem: 'resource/partyStoreForHiredWorker',
      showItem: 'parties/show',
      makeNakladnoyPdf: 'resource/makeNakladnoyPdf',
      updateItem: 'parties/update',
      rejectProduct: 'warehouse/rejectProduct',
    }),
  },
}
</script>
