<template>
  <b-modal
    id="modal-lg"
    v-model="visible"
    cancel-variant="outline-secondary"
    size="xl"
    no-close-on-backdrop
    centered
    hide-footer
    @close="close"
  >
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
    >
      <template #cell(NUM)="data">
        {{ data.index+1 }}
      </template>
      <template #cell(monthly_average_payment)="data">
        {{ (data.item.monthly_average_payment/100)|formatPrice }}
      </template>
      <template #cell(total_debt_sum)="data">
        {{ (data.item.total_debt_sum/100)|formatPrice }}
      </template>
      <template #cell(overdue_debt_sum)="data">
        {{ (data.item.overdue_debt_sum/100)|formatPrice }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
export default {
  props: {
    report: {
      type: [Array, Object],
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'NUM',
          label: '№',
        },
        {
          key: 'org_name',
          label: 'Кредитная организация',
          sortable: true,
        },
        {
          key: 'monthly_average_payment',
          label: 'Ежемесячно оплата',
        },
        {
          key: 'overdue_debt_sum',
          label: 'Просроченный долг',
        },
        {
          key: 'total_debt_sum',
          label: 'Общая задолженность',
        },
      ],
    }
  },
  mounted() {
    if (this.report && this.report.length) {
      this.items = this.report
    } else {
      this.items.push(this.report)
    }
  },
  methods: {
    close() {
      this.$emit('close', true)
    },
  },

}
</script>
