<template>
  <div>
    <b-card
      v-if="loading"
      class="text-center"
      style="padding: 200px"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
        :class="loading ? 'double-overlay' : ''"
      />
      <br>
      <strong class="text-center">
        Mijoz ma'lumotlari yuklanishini kuting. Bu biroz vaqtni oladi</strong>
    </b-card>
    <div v-else>
      <b-card
        v-if="!order_id"
        :title="$t('Покупатель')"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col
              md="12"
              class="mb-1"
            >
              <a
                class="float-right link"
                @click="openClientCreateModal = true"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                Yangi mijoz qo'shish</a>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('Введите ПИНФЛ Покупателя')"
                label-for="v-pin"
              >
                <b-form-input
                  id="v-pin"
                  v-model="form.pin"
                  v-mask="'##############'"
                  :disabled="$route.query.step == 'form'"
                  placeholder="00000000000000"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <div
                v-if="isCoBorrower"
                class=""
              >
                <b-form-group
                  :label="$t('Введите СТИР Созаемщика')"
                  label-for="v-co_pin"
                >
                  <b-form-input
                    id="v-co_pin"
                    v-model="form.co_pin"
                    v-mask="'##############'"
                    placeholder="00000000000000"
                  />
                </b-form-group>

                <b-button
                  variant="outline-danger"
                  class="btn-tour-skip float-right"
                  @click="closeCoBorrower"
                >
                  <feather-icon
                    icon="XIcon"
                    size="16"
                  />
                </b-button>
              </div>
              <template v-else-if="!isShowOrder">
                <b-button
                  variant="outline-primary"
                  class="btn-tour-skip mt-2"
                  @click="isCoBorrower = true"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="12"
                  />
                  <span class="mr-25 align-middle">Добавить Созаемщика</span>
                </b-button>
              </template>
            </b-col>
          </b-row>
          <div
            v-if="isPinFull && !client_data"
            class="text-center mt-3"
          >
            <b-button
              variant="primary"
              class="btn-tour-skip"
              @click="getClient"
            >
              <feather-icon
                icon="SearchIcon"
                size="12"
              />
              <span class="mr-25 align-middle">Поиск</span>
            </b-button>
          </div>

          <hr>

          <div
            v-if="client_data"
            class="x-card text-center"
          >
            <h4 class="">
              <b>Клиент:</b>
              {{
                [
                  client_data.lastname,
                  client_data.firstname,
                  client_data.middlename,
                ].join(" ")
              }}
            </h4>
            <h4
              v-if="client2_data"
              class=""
            >
              <b>Созаемщик:</b>
              {{
                [
                  client2_data.lastname,
                  client2_data.firstname,
                  client2_data.middlename,
                ].join(" ")
              }}
            </h4>
            <b-button
              v-if="!isShowOrder"
              variant="primary"
              class="btn-tour-skip"
              @click="openStrategyModal = true"
            >
              <span class="mr-25 align-middle">Проверить лимит</span>
            </b-button>
          </div>
        </b-form>
      </b-card>
      <!-- Response -->
      <b-row>
        <b-col
          v-if="!order_id"
          :class="clientReport || selfShopInfo ? '' : 'double-overlay'"
          md="6"
        >
          <b-card
            v-if="response_loading"
            class="text-center"
            style="padding: 200px"
          >
            <b-spinner
              label="Loading..."
              variant="primary"
              style="width: 3rem; height: 3rem"
              :class="response_loading ? 'double-overlay' : ''"
            />
          </b-card>
          <b-card
            v-else
            :title="$t('Natija')"
          >
            <self-shop
              v-if="selfShopInfo"
              :self-shop-info="selfShopInfo"
              :client_data="client_data"
              :client2_data="client2_data"
              class="mb-2"
            />
            <response
              v-if="!loading"
              :form="form"
              :self-shop-info="selfShopInfo"
              :has-exception="hasException"
              @createExceptionOrder="createExceptionOrder"
              @createOrder="createOrder"
            />
          </b-card>
        </b-col>
        <!-- Contract -->
        <b-col
          v-if="order_id"
          md="12"
        >
          <b-card :title="$t('Информация о контракте')">
            <contract-show :order-id="order_id" />
          </b-card>
        </b-col>
        <!-- Contract -->
        <b-col
          v-else
          md="6"
        >
          <b-card :title="$t('Shartnoma')">
            <!-- Form -->
            <hired-order-form
              v-if="user.role.name == 'hired_worker'"
              :class="isShowOrder ? '' : 'double-overlay'"
              :form-data="form"
              @onSuccess="setSchedule"
            />
            <order-form
              v-else
              :class="isShowOrder ? '' : 'double-overlay'"
              :form-data="form"
              @onSuccess="setSchedule"
            />
          </b-card>
        </b-col>

        <!-- Show -->
        <b-col
          v-if="order_id"
          :class="order_id ? '' : 'double-overlay'"
          cols="12"
        >
          <OrderShow :orderid="order_id" />
        </b-col>
      </b-row>

      <div v-if="openStrategyModal">
        <ChooseStrategy
          :form="form"
          @close="openStrategyModal = false"
          @save="checkClients"
        />
      </div>

      <div v-if="openClientCreateModal">
        <ClientCreate @close="closeClientCreateModal" />
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import OrderForm from './components/new_order.vue'
import HiredOrderForm from './components/new_hired_order.vue'
import Response from './components/response.vue'
import OrderShow from './show.vue'
import ContractShow from './contractShow.vue'
import ChooseStrategy from './components/choose_strategy.vue'
import ClientCreate from './components/client_create.vue'
import SelfShop from './components/selfShop.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    OrderForm,
    ClientCreate,
    ChooseStrategy,
    Response,
    OrderShow,
    ContractShow,
    HiredOrderForm,
    SelfShop,
  },
  data() {
    return {
      selfShopInfo: null,
      is_add_product: false,
      isCoBorrower: false,
      client_data: null,
      client2_data: null,
      isPinFull: false,
      openStrategyModal: false,
      openClientCreateModal: false,
      loading: false,
      reportData: null,
      isShowOrder: false,
      schedule_data: null,
      order_id: null,
      response_loading: false,
      hasException: false,
      form: {
        pin: null,
        co_pin: null,
        contract_type: 1,
        client_id: null,
        client2_id: null,
        self_shop_report_for_client_id: null,
        self_shop_report_for_client2_id: null,
        report_strategy_id: null,
        report2_strategy_id: null,
        card_number4: null,
        card2_number4: null,
      },
    }
  },
  computed: {
    ...mapGetters({ clientReport: 'resource/CLIENT_REPORT' }),
  },
  watch: {
    'form.pin': function (val) {
      if (val && val.length == 14) {
        this.isPinFull = true
        this.getClient()
      } else {
        this.client_data = null
        this.isPinFull = false
      }
      this.$store.commit('resource/SET_REPORT', null)
    },
    'form.co_pin': function (val) {
      if (val && val.length == 14) {
        this.isPinFull = true
        this.form.contract_type = 2
        this.getClient2()
      } else {
        this.form.contract_type = 1
        this.client2_data = null
        this.isPinFull = false
      }
      this.$store.commit('resource/SET_REPORT', null)
    },
  },
  mounted() {
    if (this.$route.query.pin) {
      this.form.pin = this.$route.query.pin
    }
    if (this.$route.query && this.$route.query.step) {
      this.changeQuery('step', null)
    }
    if (this.$route.query && this.$route.query.order_id) {
      this.order_id = this.$route.query.order_id
    }
    this.$store.commit('resource/SET_REPORT', null)
  },
  methods: {
    ...mapActions({
      fileUpload: 'client/fileUpload',
      clientCreate: 'client/create',
      fetchClients: 'client/clients',
      getClientReport: 'resource/getClientReport',
      makeReport: 'resource/makeReport',
      makeReportFromSelfShop: 'resource/makeReportFromSelfShop',
    }),
    createExceptionOrder(expData) {
      this.loading = true
      this.response_loading = true
      if (expData) {
        this.form.self_shop_report_for_client_id = expData.self_shop_report_for_client_id
        this.form.self_shop_report_for_client2_id = expData.self_shop_report_for_client2_id
      }
      this.makeReport(this.form)
        .then(() => {
          this.loading = false
          this.response_loading = false
          this.hasException = false
        })
        .finally(() => {
          this.loading = false
          this.response_loading = false
        })
    },
    checkClients() {
      this.loading = true
      this.makeReportFromSelfShop(this.form)
        .then(res => {
          if (res.data) {
            this.selfShopInfo = res.data
            const selfClient1 = res.data.selfShopReportForClient
            const selfClient2 = res.data.selfShopReportForClient2

            if (
              selfClient1
              && selfClient1.result_data.result_from_self_shop == 1
            ) {
              if (
                selfClient2
                && selfClient2.result_data.result_from_self_shop == 1
              ) {
                this.form.self_shop_report_for_client_id = selfClient1.id
                this.form.self_shop_report_for_client2_id = selfClient2.id
                this.createExceptionOrder()
              } else {
                this.form.self_shop_report_for_client_id = selfClient1.id
                if (selfClient2) {
                  this.form.self_shop_report_for_client2_id = selfClient2.id
                }
                this.createExceptionOrder()
              }
            } else if (
              selfClient2
              && selfClient2.result_data.result_from_self_shop == 1
            ) {
              if (
                selfClient1
                && selfClient1.result_data.result_from_self_shop == 1
              ) {
                this.form.self_shop_report_for_client_id = selfClient1.id
                this.form.self_shop_report_for_client2_id = selfClient2.id
                this.createExceptionOrder()
              } else {
                if (selfClient1) {
                  this.form.self_shop_report_for_client_id = selfClient1.id
                }
                this.form.self_shop_report_for_client2_id = selfClient2.id
                this.createExceptionOrder()
              }
            } else {
              this.loading = false
            }
            // exception case
            if (
              (selfClient1
                && selfClient1.result_data.result_from_self_shop == 0)
              || (selfClient2
                && selfClient2.result_data.result_from_self_shop == 0)
            ) {
              this.hasException = true
            } else {
              this.hasException = false
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
          console.log(77, this.clientReport)
        })
    },

    getClient() {
      this.fetchClients({ pin: this.form.pin })
        .then(res => {
          if (res.success && res.data && res.data.data.length) {
            this.client_data = res.data.data[0]
            this.form.client_id = res.data.data[0].id
          } else {
            showToast('danger', this.$t('Клиент не найден'), 'XIcon')
          }
        })
        .catch(() => {
          showToast('danger', this.$t('Клиент не найден'), 'XIcon')
        })
    },
    getClient2() {
      this.fetchClients({ pin: this.form.co_pin })
        .then(res => {
          if (res.success && res.data && res.data.data.length) {
            this.client2_data = res.data.data[0]
            this.form.client2_id = res.data.data[0].id
          } else {
            showToast('danger', this.$t('Клиент не найден'), 'XIcon')
          }
        })
        .catch(() => {
          showToast('danger', this.$t('Клиент не найден'), 'XIcon')
        })
    },

    async onChangeFile(event, type) {
      if (event.target.files[0].size <= 15360000) {
        // this.fileLoading[loading+]
        const fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('directory', 'passport')
        this.fileUpload(fileData)
          .then(res => {
            if (res.success) {
              switch (type) {
                case 1:
                  this.form.passport_with_self_url = res.data.path
                  break
                case 2:
                  this.form.passport_first_page_url = res.data.path
                  break
                case 3:
                  this.form.passport_with_address_url = res.data.path
                  break
                default:
                  break
              }
            }
          })
          .catch(err => {
            showToast('danger', err, 'XIcon')
          })
          .finally(() => {})
      } else {
        showToast(
          'danger',
          this.$t('Файл ҳажми 15 МБ дан кичик бўлиши лозим'),
          'XIcon',
        )
      }
    },
    deleteProduct(index) {
      this.form.products.splice(index, 1)
    },
    priceProduct(index) {
      this.form.products[index].summa = this.form.products[index].price * this.form.products[index].count
    },
    calcPeriod(period) {
      this.form.total_summ = 0
      this.form.products.forEach(product => {
        this.form.total_summ += product.summa * 1.4
        this.form.total_period = this.form.total_summ / period
      })
    },
    addProduct() {
      this.is_add_product = true
      this.form.products.push({
        category_id: 1,
        name: '',
        count: 1,
        summa: null,
        cost: null,
        imei: null,
      })
    },
    setSchedule(data) {
      this.schedule_data = data.schedule
      this.isShowOrder = false
      this.order_id = data.id
      this.changeQuery('order_id', data.id)
    },
    closeCoBorrower() {
      this.isCoBorrower = false
      this.form.co_pin = null
      this.client2_data = null
      this.form.client2_id = null
    },

    createOrder(data) {
      if (data.isExcep) {
        this.getClientReport({ id: this.clientReport.id })
      }
      this.isShowOrder = true
      this.changeQuery('step', 'form')
    },

    closeClientCreateModal(data) {
      if (data.data && data.data.pin) {
        if (!this.form.pin) {
          this.form.pin = data.data.pin
        } else if (this.isCoBorrower && !this.form.co_pin) {
          this.form.co_pin = data.data.pin
        }
      }
      this.openClientCreateModal = false
    },
  },
}
</script>
