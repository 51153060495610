<template>
  <b-modal
    id="modal-lg"
    v-model="visible"
    cancel-variant="outline-secondary"
    size="xl"
    hide-footer
    no-close-on-backdrop
    centered
    @close="close"
  >
    <h3 class="text-center">
      Отчёт по Карте
      <!-- {{ report?1:2 }} -->
    </h3>
    <table
      v-if="report && report.length"
      class="table table-bordered"
    >
      <thead>
        <tr>
          <th scope="col">
            №
          </th>
          <th scope="col">
            Месяц
          </th>
          <th scope="col">
            Общий доход
          </th>
          <th scope="col">
            Общий расход
          </th>
          <th scope="col">
            Общие расходы P2P
          </th>
          <th scope="col">
            Всего наличных
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in report">
          <tr :key="index+'name'">
            <th colspan="6">
              <b class="text-center">{{ item.CardBank }}</b> - {{ item.Pan }}
              <!-- {{ item }} -->
            </th>
          </tr>
          <template v-for="(itemYear, indexItem) in item.YearInfo">
            <tr :key="indexItem+'y'+itemYear.Year+index">
              <th colspan="6">
                {{ itemYear.Year }}
              </th>
            </tr>
            <template v-for="(itemMonth, indexMonth) in itemYear.MonthsInfo">
              <tr :key="indexMonth+'month'+itemMonth.Month+index">
                <td>{{ indexMonth+1 }}</td>
                <td class="font-weight-bold">
                  {{ itemMonth.Month }}
                </td>
                <td>{{ (itemMonth.TotalIncome/100) |formatPrice }}</td>
                <td>{{ (itemMonth.TotalExpense/100) |formatPrice }}</td>
                <td>{{ (itemMonth.TotalP2PExpense/100) |formatPrice }}</td>
                <td>{{ (itemMonth.TotalCash/100) |formatPrice }}</td>
              </tr>
            </template>
          </template>
        </template>

      </tbody>
    </table>
  </b-modal>
</template>

<script>
export default {
  props: {
    report: {
      type: [Array, Object],
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close', true)
    },
  },

}
</script>
