<template>
  <div>
    <b-overlay
      :show="loading"
    >
      <b-card
        :title="$t('Shartnoma')"
      >
        <div
          class="text-center mt-3"
        >
          <b-button
            variant="outline-primary"
            class="btn-tour-skip"
            @click="getPdf"
          >
            <feather-icon
              icon="DownloadIcon"
              size="12"
            />
            <span class="mr-25 align-middle">Shartnomani yuklab olish</span>
          </b-button>
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :text="language"
            variant="outline-primary"
          >
            <b-dropdown-item @click="language='uz'">
              UZ
            </b-dropdown-item>
            <b-dropdown-item @click="language='ru'">
              RU
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <hr>
        <!--  -->
        <div
          v-if="isWaiting"
          class="text-center fs22 mb-3"
        >
          <b-badge
            pill
            variant="light-warning"
          >
            Yuklangan fayllar tasdiqlanishi kutilmoqda.
          </b-badge>
        </div>
        <div
          v-if="isRejected"
          class="text-center fs22"
        >
          <b-badge
            pill
            variant="light-danger"
          >
            Yuklangan fayllar tasdiqlanmadi. Iltimos tekshirib qayta kiriting
          </b-badge> <br>
          <span class="mt-3"><b>Rad etish sababi:</b> {{ contract.images_deny_reason }}</span>
        </div>
        <div
          v-if="isConfirmed"
          class="text-center fs22 mb-3"
        >
          <b-badge
            pill
            variant="light-success"
          >
            Fayllar tasdiqlangan
          </b-badge> <br>
        </div>
        <template v-if="!isShow || isRejected">
          <h4 class="card-title">
            {{ $t('Загрузить фото паспорта и контракта') }}
          </h4>
          {{ $t('Допустимые форматы изображения') }} - JPG, PNG, BMP
          <b-row class="mt-2">
            <!-- passport_url -->
            <b-col
              class="passport-upload"
            >
              <div
                v-loading="loads.loading1"
                class="text-center"
              >
                <template v-if="form.passport_url && form.passport_url.slice(-3) == 'pdf'">
                  <div @click="openPdf(storageUrl + form.passport_url)">
                    <pdf
                      :src="storageUrl + form.passport_url"
                      height="162"
                      width="162"
                      class="img-uploaded"
                    />
                  </div>
                </template>
                <template v-else-if="form.passport_url">
                  <img
                    :src="storageUrl + form.passport_url"
                    height="162"
                    width="162"
                    class="img-uploaded"
                  >
                </template>
                <template v-else>
                  <img
                    src="@/assets/images/svg/fileuploader-placeholder.svg"
                    class="img-placeholder"
                    alt="image"
                    width="162"
                    @click="$refs.firstPassport.click()"
                  >
                </template>
              </div>
              <div class="text-center ">
                <input
                  ref="firstPassport"
                  accept="image/jpeg, image/jpg, image/png, application/pdf"
                  type="file"
                  class="d-none"
                  @change="e => onChangeFile(e, 1, 'passport')"
                >
                <span
                  class="upload-label"
                  @click="$refs.firstPassport.click()"
                >
                  <template v-if="form.passport_url">
                    <feather-icon
                      icon="CheckIcon"
                      size="12"
                    />
                    {{ $t('Первая страница паспорта') }}
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                    />
                  </template>
                  <template v-else>
                    <feather-icon
                      icon="PlusSquareIcon"
                      size="12"
                    /> {{ $t('Первая страница паспорта') }}
                  </template>
                </span>
              </div>
            </b-col>
            <!-- passport_address_url -->
            <b-col
              class="passport-upload"
            >
              <div
                v-loading="loads.loading5"
                class="text-center"
              >
                <template v-if="form.passport_address_url && form.passport_address_url.slice(-3) == 'pdf'">
                  <div @click="openPdf(storageUrl + form.passport_address_url)">
                    <pdf
                      :src="storageUrl + form.passport_address_url"
                      height="162"
                      width="162"
                      class="img-uploaded"
                    />
                  </div>
                </template>
                <template v-else-if="form.passport_address_url">
                  <img
                    :src="storageUrl + form.passport_address_url"
                    height="162"
                    width="162"
                    class="img-uploaded"
                  >
                </template>
                <template v-else>
                  <img
                    src="@/assets/images/svg/fileuploader-placeholder.svg"
                    class="img-placeholder"
                    alt="image"
                    width="162"
                    @click="$refs.addressPassport.click()"
                  >
                </template>
              </div>
              <div class="text-center">
                <input
                  ref="addressPassport"
                  accept="image/jpeg, image/jpg, image/png, application/pdf"
                  type="file"
                  class="d-none"
                  @change="e => onChangeFile(e, 5, 'passport')"
                >
                <span
                  class="upload-label"
                  @click="$refs.addressPassport.click()"
                >
                  <template v-if="form.passport_address_url">
                    <feather-icon
                      icon="CheckIcon"
                      size="12"
                    />
                    {{ $t('Пропиской страница паспорта') }}
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                    />
                  </template>
                  <template v-else>
                    <feather-icon
                      icon="PlusSquareIcon"
                      size="12"
                    /> {{ $t('Пропиской страница паспорта') }}
                  </template>
                </span>
              </div>
            </b-col>
            <!-- contract_pdf_url -->
            <b-col
              class="passport-upload"
            >
              <div
                v-loading="loads.loading3"
                class="text-center"
              >
                <template v-if="form.contract_pdf_url && form.contract_pdf_url.slice(-3) == 'pdf'">
                  <div @click="openPdf(storageUrl + form.contract_pdf_url)">
                    <pdf
                      :src="storageUrl + form.contract_pdf_url"
                      height="162"
                      width="162"
                      class="img-uploaded"
                    />
                  </div>
                </template>
                <template v-else-if="form.contract_pdf_url">
                  <img
                    :src="storageUrl + form.contract_pdf_url"
                    height="162"
                    width="162"
                    class="img-uploaded"
                  >
                </template>
                <template v-else>
                  <img
                    src="@/assets/images/svg/fileuploader-placeholder.svg"
                    class="img-placeholder"
                    alt="image"
                    width="162"
                    @click="$refs.contract_pdf_url.click()"
                  >
                </template>
              </div>
              <div class="text-center ">
                <input
                  ref="contract_pdf_url"
                  type="file"
                  accept="image/jpeg, image/jpg, image/png, application/pdf"
                  class="d-none"
                  @change="e => onChangeFile(e, 3, 'contracts')"
                >
                <span
                  class="upload-label"
                  @click="$refs.contract_pdf_url.click()"
                >
                  <template v-if="form.contract_pdf_url">
                    <feather-icon
                      icon="CheckIcon"
                      size="12"
                    />
                    {{ $t('Контракт с подписью') }}
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                    />
                  </template>
                  <template v-else>
                    <feather-icon
                      icon="PlusSquareIcon"
                      size="12"
                    /> {{ $t('Контракт с подписью') }}
                  </template>
                </span>
              </div>
            </b-col>
            <!-- co borrower -->
            <template v-if="contract.client2">
              <!-- passport_url2 -->
              <b-col
                class="passport-upload"
              >
                <div
                  v-loading="loads.loading2"
                  class="text-center"
                >
                  <template v-if="form.passport_url2 && form.passport_url2.slice(-3) == 'pdf'">
                    <div @click="openPdf(storageUrl + form.passport_url2)">
                      <pdf
                        :src="storageUrl + form.passport_url2"
                        height="162"
                        width="162"
                        class="img-uploaded"
                      />
                    </div>
                  </template>
                  <template v-else-if="form.passport_url2">
                    <img
                      :src="storageUrl + form.passport_url2"
                      height="162"
                      width="162"
                      class="img-uploaded"
                    >
                  </template>
                  <template v-else>
                    <img
                      src="@/assets/images/svg/fileuploader-placeholder.svg"
                      alt="image"
                      width="162"
                      class="img-placeholder"
                      @click="$refs.passportUrl2.click()"
                    >
                  </template>
                </div>
                <!-- label -->
                <div class="text-center ">
                  <input
                    ref="passportUrl2"
                    type="file"
                    accept="image/jpeg, image/jpg, image/png, application/pdf"
                    class="d-none"
                    @change="e => onChangeFile(e, 2, 'passport')"
                  >
                  <span
                    class="upload-label"
                    @click="$refs.passportUrl2.click()"
                  >
                    <template v-if="form.passport_url2">
                      <feather-icon
                        icon="CheckIcon"
                        size="12"
                      />
                      {{ $t('Первая страница паспорта созаемщика') }}
                      <feather-icon
                        icon="Trash2Icon"
                        size="12"
                      />
                    </template>
                    <template v-else>
                      <feather-icon
                        icon="PlusSquareIcon"
                        size="12"
                      /> {{ $t('Первая страница паспорта созаемщика') }}
                    </template>
                  </span>
                </div>
              </b-col>
              <!-- passport_address_url2 -->
              <b-col
                class="passport-upload"
              >
                <div
                  v-loading="loads.loading4"
                  class="text-center"
                >
                  <template v-if="form.passport_address_url2 && form.passport_address_url2.slice(-3) == 'pdf'">
                    <div @click="openPdf(storageUrl + form.passport_address_url2)">
                      <pdf
                        :src="storageUrl + form.passport_address_url2"
                        height="162"
                        width="162"
                        class="img-uploaded"
                      />
                    </div>
                  </template>
                  <template v-else-if="form.passport_address_url2">
                    <img
                      :src="storageUrl + form.passport_address_url2"
                      height="162"
                      width="162"
                      class="img-uploaded"
                    >
                  </template>
                  <template v-else>
                    <img
                      src="@/assets/images/svg/fileuploader-placeholder.svg"
                      class="img-placeholder"
                      alt="image"
                      width="162"
                      @click="$refs.passportaddressurl2.click()"
                    >
                  </template>
                </div>
                <div class="text-center ">
                  <input
                    ref="passportaddressurl2"
                    type="file"
                    accept="image/jpeg, image/jpg, image/png, application/pdf"
                    class="d-none"
                    @change="e => onChangeFile(e, 4, 'passport')"
                  >
                  <span
                    class="upload-label"
                    @click="$refs.passportaddressurl2.click()"
                  >
                    <template v-if="form.passport_address_url2">
                      <feather-icon
                        icon="CheckIcon"
                        size="12"
                      />
                      {{ $t('Пропиской страница паспорта созаемщика') }}
                      <feather-icon
                        icon="Trash2Icon"
                        size="12"
                      />
                    </template>
                    <template v-else>
                      <feather-icon
                        icon="PlusSquareIcon"
                        size="12"
                      /> {{ $t('Пропиской страница паспорта созаемщика') }}
                    </template>
                  </span>
                </div>
              </b-col>
            </template>
          </b-row>
          <!--  -->
          <!-- v-if="!isImagesChecker && isOpenSendBtn" -->
          <div
            v-if="isOpenSendBtn"
            v-can="'contracts.store'"
            class="text-right mt-3"
            :class="isAllUpladed?'':'double-overlay'"
          >
            <b-button
              variant="primary"
              class="btn-tour-skip"
              @click="save"
            >
              <span class="mr-25 align-middle">Tekshirishga yuborish</span>
            </b-button>
          </div>
        </template>
        <template v-else>
          <!-- show -->
          <div
            v-if="contract"
            class="d-flex justify-content-start felx-wrap"
          >
            <div class="mr-5">
              <template v-if="contract.passport_url && contract.passport_url.slice(-3) == 'pdf'">
                <div @click="openPdf(storageUrl + contract.passport_url)">
                  <pdf
                    :src="storageUrl + contract.passport_url"
                    height="162"
                    width="162"
                    class="img-uploaded"
                  />
                </div>
              </template>
              <template v-else-if="contract.passport_url">
                <el-image
                  fit="cover"
                  class="profile-avatar"
                  :src="storageUrl + contract.passport_url"
                  :preview-src-list="[storageUrl + contract.passport_url]"
                />
              </template>
              <a class="d-block mb-1 fs16">
                <feather-icon
                  icon="ImageIcon"
                  size="12"
                />
                Первая страница паспорта</a>
            </div>
            <div class="mr-5">
              <template v-if="contract.passport_address_url && contract.passport_address_url.slice(-3) == 'pdf'">
                <div @click="openPdf(storageUrl + contract.passport_address_url)">
                  <pdf
                    :src="storageUrl + contract.passport_address_url"
                    height="162"
                    width="162"
                    class="img-uploaded"
                  />
                </div>
              </template>
              <template v-else-if="contract.passport_address_url">
                <el-image
                  fit="cover"
                  class="profile-avatar"
                  :src="storageUrl + contract.passport_address_url"
                  :preview-src-list="[storageUrl + contract.passport_address_url]"
                />
              </template>
              <a class="d-block mb-1 fs16">
                <feather-icon
                  icon="ImageIcon"
                  size="12"
                />
                Пропиской страница паспорта</a>
            </div>
            <div
              v-if="contract.client2"
              class="mr-5"
            >
              <template v-if="contract.passport_url2 && contract.passport_url2.slice(-3) == 'pdf'">
                <div @click="openPdf(storageUrl + contract.passport_url2)">
                  <pdf
                    :src="storageUrl + contract.passport_url2"
                    height="162"
                    width="162"
                    class="img-uploaded"
                  />
                </div>
              </template>
              <template v-else-if="contract.passport_url2">
                <el-image
                  fit="cover"
                  class="profile-avatar"
                  :src="storageUrl + contract.passport_url2"
                  :preview-src-list="[storageUrl + contract.passport_url2]"
                />
              </template>
              <a
                class="d-block mb-1 fs16"
              >
                <feather-icon
                  icon="ImageIcon"
                  size="12"
                />
                {{ $t('Первая страница паспорта созаемщика') }}</a>
            </div>
            <div
              v-if="contract.client2"
              class="mr-5"
            >
              <template v-if="contract.passport_address_url2 && contract.passport_address_url2.slice(-3) == 'pdf'">
                <div @click="openPdf(storageUrl + contract.passport_address_url2)">
                  <pdf
                    :src="storageUrl + contract.passport_address_url2"
                    height="162"
                    width="162"
                    class="img-uploaded"
                  />
                </div>
              </template>
              <template v-else-if="contract.passport_address_url2">
                <el-image
                  fit="cover"
                  class="profile-avatar"
                  :src="storageUrl + contract.passport_address_url2"
                  :preview-src-list="[storageUrl + contract.passport_address_url2]"
                />
              </template>
              <a
                class="d-block mb-1 fs16"
              >
                <feather-icon
                  icon="ImageIcon"
                  size="12"
                />
                {{ $t('Пропиской страница паспорта созаемщика') }}</a>
            </div>
            <div class="mr-5">
              <template v-if="contract.contract_pdf_url && contract.contract_pdf_url.slice(-3) == 'pdf'">
                <div @click="openPdf(storageUrl + contract.contract_pdf_url)">
                  <pdf
                    :src="storageUrl + contract.contract_pdf_url"
                    height="162"
                    width="162"
                    class="img-uploaded"
                  />
                </div>
              </template>
              <template v-else-if="contract.contract_pdf_url">
                <el-image
                  fit="cover"
                  class="profile-avatar"
                  :src="storageUrl + contract.contract_pdf_url"
                  :preview-src-list="[storageUrl + contract.contract_pdf_url]"
                />
              </template>
              <a
                class="d-block mb-1 fs16"
              >
                <feather-icon
                  icon="ImageIcon"
                  size="12"
                />
                {{ $t('Контракт с подписью') }}</a>
            </div>
          </div>
          <div
            v-if="contract && contract.documents && contract.documents.length"
            class="d-block mr-5"
          >
            <hr>
            <h3 class="text-center mb-2">
              Qo'shimcha fayllar
            </h3>
            <div class="d-flex justify-content-start flex-wrap">
              <div
                v-for="(item, index) in contract.documents"
                :key="index"
                class="mx-2"
              >
                <template v-if="item.url && item.url.slice(-3) == 'pdf'">
                  <div @click="openPdf(storageUrl + item.url)">
                    <pdf
                      :src="storageUrl + item.url"
                      height="162"
                      width="162"
                      class="img-uploaded"
                    />
                  </div>
                </template>
                <template v-else-if="item.url">
                  <el-image
                    fit="cover"
                    class="profile-avatar"
                    :src="storageUrl + item.url"
                    :preview-src-list="[storageUrl + item.url]"
                  />
                </template>
                <a
                  class="d-block mb-1 fs16"
                >
                  <feather-icon
                    icon="ImageIcon"
                    size="12"
                  />
                  {{ item.name }}
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    color="red"
                    @click="deleteItem(item)"
                  />
                </a>
              </div>
            </div>
          </div>
        </template>

        <div
          v-if="($route.name == 'ConfirmContractPhotos-show' && isWaiting)"
          v-can="'contracts.update-images-confirmation-status'"
          class="text-left mt-3"
        >
          <b-button
            variant="outline-danger"
            class="btn-tour-skip"
            @click="confirmation(2)"
          >
            <span class="mr-25 align-middle">Shartnomani rad etish</span>
          </b-button>
          <b-button
            variant="primary"
            class="btn-tour-skip ml-5"
            @click="confirmation(1)"
          >
            <span class="mr-25 align-middle">Shartnomani tasdiqlash</span>
          </b-button>
          <RejectModal
            :visible="reject_modal"
            @close="reject_modal=false"
            @rejected="rejectPassport"
          />
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import axios from 'axios'
import pdf from 'vue-pdf'
import Ripple from 'vue-ripple-directive'
import RejectModal from './components/reject_passport_modal.vue'

export default {
  components: { RejectModal, pdf },
  directives: {
    Ripple,
  },
  props: {
    orderid: {
      type: [Number, String],
      default: () => null,
    },
    isShow: {
      type: Boolean,
      default: () => false,
    },
    isContractShow: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      contract_uploaded: false,
      contract_path: null,
      loading: false,
      reject_modal: false,
      language: 'uz',
      add_documents: null,
      loads: {
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
      },
      form: {
        id: null,
        passport_url: null,
        passport_address_url: null,
        passport_url2: null,
        contract_pdf_url: null,
        passport_address_url2: null,
      },
    }
  },
  computed: {
    ...mapGetters({ contract: 'contract/GET_ITEM' }),
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    storageContractUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/contracts/`
    },
    orderId() {
      if (this.$route.query.order_id) {
        return this.$route.query.order_id
      }
      if (this.$route.params.id) {
        return this.$route.params.id
      }
      return this.orderid
    },
    isRejected() {
      return this.contract.images_confirmation_status == 2
    },
    isConfirmed() {
      return this.contract.images_confirmation_status == 1
    },
    isWaiting() {
      return (this.isUploadFiles && this.contract.images_confirmation_status == 0)
    },
    isOpenSendBtn() {
      return (this.contract && (this.contract.images_confirmation_status == null || this.contract.images_confirmation_status == 2))
    },
    isUploadFiles() {
      if (this.contract && this.contract.client2) {
        return (this.contract.contract_pdf_url && this.contract.passport_url2 && this.contract.passport_address_url2
         && this.contract.passport_url && this.contract.passport_address_url)
      }
      return (this.contract.contract_pdf_url
       && this.contract.passport_url && this.contract.passport_address_url)
    },
    isAllUpladed() {
      // return (Object.keys(this.form).every(key => this.form[key] !== null))
      if (this.contract && this.contract.client2) {
        return (this.form.contract_pdf_url && this.form.passport_url2 && this.form.passport_address_url2
         && this.form.passport_url && this.form.passport_address_url)
      }
      return (this.form.contract_pdf_url
       && this.form.passport_url && this.form.passport_address_url)
    },
  },
  watch: {
  },
  async mounted() {
    this.form.id = this.orderId
    if (this.$route.params && this.$route.params.id) {
      this.setFiles()
    }
    if (!this.isContractShow) {
      await this.getAction()
    }
  },
  methods: {
    ...mapActions({
      update: 'contract/update',
      getItemAction: 'contract/show',
      deleteAddDocument: 'contract/deleteAddDocument',
      fileUpload: 'client/fileUpload',
      makeContractPdf: 'resource/makeContractPdf',
      updateImagesConfirmation: 'contract/updateImagesConfirmation',
    }),
    getAction() {
      this.loding = true
      this.getItemAction({ id: this.orderId, relations: 'client|client2|payments|visibleProducts.category|documents' })
        .finally(() => {
          this.loding = false
        })
    },
    confirmation(type) {
      if (type == 1) {
        this.$bvModal.msgBoxConfirm(this.$t('Shartnomani tasdiqlaysizmi?'), { centered: true })
          .then(value => {
            if (value) {
              this.confirmPassportAction({ images_confirmation_status: type, id: this.orderId })
            }
          })
      } else {
        this.reject_modal = true
      }
    },
    rejectPassport(form) {
      this.reject_modal = false
      this.confirmPassportAction({ images_confirmation_status: 2, id: this.orderId, images_deny_reason: form.images_deny_reason })
    },

    confirmPassportAction(data) {
      this.loading = true
      this.updateImagesConfirmation(data)
        .then(() => {
          this.goTo('ConfirmContractPhotos')
          showToast('success', this.$t('Успешно подтверждено'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    openPdf(link) {
      window.open(link, '_blank')
    },
    save() {
      this.loading = true
      this.update(this.form).then(res => {
        if (res.success) {
          showToast('success', this.$t("Shartnoma muvaffaqiyatli jo'natildi"), 'XIcon')
          if (this.isRejected || this.$route.name == 'OrderCreate') {
            this.getAction()
            // this.$router.push({ name: 'OrdersIndex' })
          } else {
            this.$emit('close', true)
          }
        }
      })
        .finally(() => {
          this.loading = false
        })
    },
    getPdf() {
      this.makeContractPdf({ id: this.orderId, language: this.language }).then(res => {
        this.contract_path = `${this.storageContractUrl + res.data}.pdf`
        window.open(`${this.storageContractUrl + res.data}.pdf`, '_blank')
        // this.downloadFile()
        // this.downloadAxiosFile()
      })
    },
    downloadAxiosFile() {
      axios({
        url: this.storageContractUrl, // download file link goes here
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
      }).then(res => {
        const FILE = window.URL.createObjectURL(new Blob([res.data]))

        const docUrl = document.createElement('x')
        docUrl.href = FILE
        docUrl.setAttribute('download', 'sample.pdf')
        document.body.appendChild(docUrl)
        docUrl.click()
      })
    },
    async downloadFile() {
      const response = await this.$http.get(`${this.contract_path}`, {
        responseType: 'arraybuffer',
      })
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'test.pdf'
      link.click()
    },
    async onChangeFile(event, type, directory) {
      console.log(event, type)
      if (event.target.files[0].size <= 21360000) {
        this.loads[`loading${type}`] = true
        const fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('directory', directory)
        this.fileUpload(fileData)
          .then(res => {
            switch (type) {
              case 1:
                this.form.passport_url = res.data.path
                break
              case 2:
                this.form.passport_url2 = res.data.path
                break
              case 3:
                this.form.contract_pdf_url = res.data.path
                break
              case 4:
                this.form.passport_address_url2 = res.data.path
                break
              case 5:
                this.form.passport_address_url = res.data.path
                break
              default:
                break
            }
          })
          .catch(err => {
            showToast('danger', err.data.message, 'XIcon')
          })
          .finally(() => {
            this.loads[`loading${type}`] = false
          })
      } else {
        showToast('danger', this.$t('Файл ҳажми 20 МБ дан кичик бўлиши лозим'), 'XIcon')
      }
    },
    deleteItem(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteAddDocument(item.id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getAction()
            })
          }
        })
    },
    setFiles() {
      this.form.passport_url = this.contract.passport_url
      this.form.passport_address_url = this.contract.passport_address_url
      this.form.passport_url2 = this.contract.passport_url2
      this.form.contract_pdf_url = this.contract.contract_pdf_url
      this.form.passport_address_url2 = this.contract.passport_address_url2
      this.add_documents = this.contract.documents
    },
  },
}
</script>
