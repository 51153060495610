<template>
  <div>
    <b-overlay :show="form_loading">
      <validation-observer ref="form" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="save">
          <b-button
            v-if="!show_add_product"
            variant="outline-primary"
            class="btn-tour-skip mb-2"
            @click="addProductShow"
          >
            <span class="mr-25 align-middle">
              <feather-icon icon="PlusIcon" />
              {{ $t("Добавить продукт") }}</span
            >
          </b-button>
          <div v-else>
            <div class="text-right">
              <b-button
                variant="outline-success"
                class="btn-tour-skip"
                @click="show_party_create = true"
              >
                <span class="mr-25 align-middle">
                  <feather-icon icon="PlusIcon" />
                  {{ $t("Приход товара") }}</span
                >
              </b-button>
            </div>
            <hr />
            <div v-for="(product, pIndex) in form.products" :key="pIndex">
              <div v-if="form.products.length == pIndex + 1" class="row">
                <b-col cols="6">
                  <b-form-group
                    :label="$t('Выберите продукта')"
                    label-for="product_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="product_id"
                      rules="required"
                    >
                      <b-form-select
                        id="product_id"
                        v-model="product.id"
                        name="name"
                        value-field="id"
                        text-field="category.name_uz"
                        :placeholder="$t('Выберите продукта')"
                        :options="products.data"
                        @change="setProduct(product.id, pIndex)"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="text-right mb-1">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mt-3"
                    @click="addNewProduct(pIndex)"
                  >
                    <feather-icon icon="PlusIcon" />
                    {{ $t("Добавить еще") }}
                  </b-button>
                </b-col>
              </div>
            </div>
          </div>
          <!--  -->
          <div v-if="form.products && form.products[0].id">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Nomi</th>
                  <th>Soni</th>
                  <th>Narxi</th>
                  <th>Jami</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <template v-for="(product, index) in form.products">
                  <tr v-if="product.id" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td
                      v-if="product.id"
                      class="text-primary"
                      @click="editProduct(index)"
                    >
                      {{ product.name }}
                    </td>
                    <td>{{ product.quantity }} dona</td>
                    <td v-if="product.id">
                      {{ product.price | formatPrice }}so'm
                    </td>
                    <td v-if="product.id">
                      {{ (product.quantity * product.price) | formatPrice }}so'm
                    </td>
                    <td>
                      <feather-icon
                        color="#FF0000"
                        icon="TrashIcon"
                        @click="deleteProduct(index)"
                      />
                    </td>
                  </tr>
                </template>
                <tr>
                  <th colspan="2">Jami</th>
                  <th colspan="4">{{ getTotalProduct() | formatPrice }}so'm</th>
                </tr>
              </tbody>
            </table>
          </div>

          <hr />
          <div class="row">
            <div class="col-12 col-sm-6">
              <b-form-group
                :label="$t('Срок рассрочки (месяц)')"
                label-for="credit_months"
              >
                <validation-provider
                  #default="{ errors }"
                  name="credit_months"
                  rules="required"
                >
                  <b-form-input
                    id="credit_months"
                    v-model="form.credit_months"
                    v-mask="'##'"
                    :disabled="!form.products[0].id"
                    :state="errors.length > 0 ? false : null"
                    name="credit_months"
                    debounce="500"
                    placeholder="12"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <b-form-group label-for="credit_amount">
                <span slot="label" class="font-weight-bolder">{{
                  $t("Сумма рассрочки") + calcLimitCredit()
                }}</span>
                <cleave
                  id="price"
                  v-model="form.credit_amount"
                  class="form-control"
                  :options="cleaveOption"
                  disabled
                  placeholder="0"
                />
              </b-form-group>
            </div>
            <div v-if="monthly_payment" class="col-6">
              <b-form-group label-for="monthly_payment">
                <span slot="label" class="font-weight-bolder">{{
                  $t("Ежемесячный платеж")
                }}</span>
                <cleave
                  id="price"
                  v-model="monthly_payment"
                  class="form-control"
                  :options="cleaveOption"
                  disabled
                  placeholder="0"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group :label="$t('Дата начала')" label-for="start_date">
                {{ form.start_date.split("-").reverse().join(".") }}
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group :label="$t('Дата окончания')" label-for="end_date">
                <validation-provider
                  #default="{ errors }"
                  name="end_date"
                  rules="required"
                >
                  <x-date-picker
                    id="end_date"
                    v-model="form.end_date"
                    :state="errors.length > 0 ? false : null"
                    name="end_date"
                    disabled
                    :placeholder="$t('Дата окончания')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group
                :label="$t('Дата оплаты')"
                label-for="credit_payment_day"
              >
                <validation-provider
                  #default="{ errors }"
                  name="credit_payment_day"
                  rules="required"
                >
                  <b-form-select
                    id="from_warehouse_id"
                    v-model="form.credit_payment_day"
                    :placeholder="$t('Дата оплаты')"
                    :options="endDays"
                    @change="changeEndDay(form.credit_payment_day)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>

          <hr />
          <h4 class="card-title">
            {{ $t("Контакты близких") }}
          </h4>
          <p>{{ $t("Пожалуйста, укажите контакты близких Вам людей.") }}</p>
          <b-row>
            <b-col cols="6">
              <h3 class="card-title">
                {{ $t("Контактное лицо - 1") }}
              </h3>
              <b-form-group
                :label="$t('Введите номер телефона')"
                label-for="v-phone-1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="v-phone-1"
                  rules="required|min:9"
                >
                  <b-form-input
                    id="v-phone-1"
                    v-model="form.near_phone1"
                    v-mask="'## #######'"
                    :state="errors.length > 0 ? false : null"
                    placeholder="90 0000000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <h3 class="card-title">
                {{ $t("Контактное лицо - 2") }}
              </h3>
              <b-form-group
                :label="$t('Введите номер телефона')"
                label-for="v-phone-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="v-phone-2"
                  rules="required|min:9"
                >
                  <b-form-input
                    id="v-phone-2"
                    v-model="form.near_phone2"
                    v-mask="'## #######'"
                    placeholder="90 0000000"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <!--  -->
          <div class="text-right mt-3">
            <b-button variant="primary" class="btn-tour-skip" @click="save">
              <span class="mr-25 align-middle">Shartnoma yaratish</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>
    <!-- Modals -->
    <div v-if="show_party_create">
      <b-modal
        v-model="show_party_create"
        size="xl"
        centered
        hide-footer
        no-close-on-backdrop
        @close="show_party_create = false"
        @cancel="show_party_create = false"
      >
        <div class="text-center">
          <PartyCreate @save="partyAdded" />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { showToast } from "@/utils/toast";
import { required } from "@validations";
import { mapActions, mapGetters } from "vuex";
import Cleave from "vue-cleave-component";
import PartyCreate from "@/components/partyCreate.vue";
import "vue-tree-halower/dist/halower-tree.min.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  name: "Create",
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
    PartyCreate,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show_party_create: false,
      show_add_product: false,
      barcodeInputLoading: false,
      monthly_payment: null,
      form: {
        products: [
          {
            id: null,
            category_id: null,
            price: null,
            quantity: 1,
            name: "",
          },
        ],
        showProducts: [
          {
            id: null,
            category_id: null,
            price: null,
            quantity: 1,
            name: "",
          },
        ],
        type: null,
        client_id: null,
        client_report_id: null,
        client2_id: null,
        client2_report_id: null,
        credit_payment_day: null,
        in_advance_payment_amount: null,
        start_date: null,
        end_date: null,
        credit_amount: null,
        credit_months: null,

        relative_first_name: null,
        relative_second_name: null,
        near_phone1: null,
        near_phone2: null,
      },
      endDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: "thousand" },
      loading: false,
      form_loading: false,
      required,
    };
  },
  computed: {
    ...mapGetters({
      products: "product/GET_ITEMS",
      clientReport: "resource/CLIENT_REPORT",
      settings: "setting/GET_ITEMS",
      client2Report: "resource/CLIENT2_REPORT",
    }),
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`;
    },
    today() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = today.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    },
  },
  watch: {
    "form.credit_months": function (val) {
      if (val && this.form.products.length) {
        this.getCreditAmount();
      }
    },
    "form.in_advance_payment_amount": function (val, oldVal) {
      if (val && this.form.products.length) {
        this.debounce(this.getCreditAmount, 1000);
      }
    },
    "form.near_phone2": function (val) {
      if (val && val.length > 8 && val == this.form.near_phone1) {
        showToast(
          "danger",
          this.$t("Telefon raqamlar teng bo'lishi mumkin emas"),
          "XIcon"
        );
        this.form.near_phone2 = "";
      }
    },
  },
  mounted() {
    this.form.start_date = this.today;
    this.fetchSettings().then(() => {
      this.form.credit_payment_day = this.settings.data.filter(
        (c) => c.code == "credit_payment_day"
      )[0].value;
    });
    this.getProductsAction({
      relations: "category",
      appends: "price",
      has_in_warehouse: 1,
    });
    this.setForm();
  },
  methods: {
    setForm() {
      if (this.formData) {
        this.form.type = this.formData.contract_type;
        if (this.client2Report && this.client2Report.id) {
          this.form.client2_report_id = this.client2Report.id;
          this.form.client2_id = this.client2Report.client_id;
        }
        if (this.clientReport && this.clientReport.id) {
          this.form.client_report_id = this.clientReport.id;
          this.form.client_id = this.clientReport.client_id;
        }
      }
    },
    partyAdded() {
      this.show_party_create = false;
      this.getProductsAction({ relations: "category" });
    },
    async save() {
      this.setForm();
      const valid = await this.validationForm();
      this.clearProductsArray();
      if (valid) {
        this.form.near_phone1 = this.form.near_phone1.replace(/\s/g, "");
        this.form.near_phone2 = this.form.near_phone2.replace(/\s/g, "");
        this.form_loading = true;
        this.store(this.form)
          .then((res) => {
            showToast(
              "success",
              this.$t("Успешно сохранено"),
              "CheckCircleIcon"
            );
            this.$emit("onSuccess", res.data);
            this.form_loading = false;
          })
          .catch((err) => {
            this.form_loading = false;
            showToast("danger", this.$t("Ошибка"), "XIcon");
          });
      } else {
        showToast("warning", this.$t("Заполните необходимые поля"));
      }
    },
    debounce(fn, delay) {
      let timer;
      return (() => {
        clearTimeout(timer);
        timer = setTimeout(() => fn(), delay);
      })();
    },
    getCreditAmount() {
      this.clearProductsArray();
      this.calculateCreditAmount(this.form).then((res) => {
        this.form.credit_amount = res.data;
        if (this.form.credit_months) {
          this.monthly_payment =
            Number(res.data) / Number(this.form.credit_months);
        } else {
          this.monthly_payment = null;
        }
      });
      const eDate = this.calcEndDate();
      if (eDate.search("/") > 0) {
        this.form.end_date = `${eDate.split("/")[2]}-${eDate
          .split("/")[0]
          .padStart(2, "0")}-${eDate.split("/")[1].padStart(2, "0")}`;
      } else {
        this.form.end_date = eDate.split(".").reverse().join("-");
      }
    },
    async validationForm() {
      let validated = false;
      await this.$refs.form.validate().then((success) => {
        validated = success;
      });
      return validated;
    },
    setProduct(id, index) {
      const data = this.products.data.find((product) => product.id == id);
      if (data) {
        this.form.products[index] = {
          id: data.id,
          category_id: data.category_id,
          price: data.price ? data.price : data.cost,
          quantity: data.quantity,
          name: data.category.name_uz,
        };
      }
      this.clearFormFields();
    },
    addNewProduct(index) {
      const data = {
        id: null,
        category_id: null,
        price: null,
        quantity: 1,
        name: "",
      };
      if (this.form.products[index].id) {
        this.form.products.push(data);
        this.clearFormFields();
      } else {
        showToast("danger", this.$t("Выберите продукта"), "XIcon");
      }
    },
    updateProduct(data) {
      if (data.index !== null) {
        this.form.products[data.index] = data;
      }
      this.clearFormFields();
    },
    addProduct() {
      this.form.products.push({
        id: null,
        category_id: null,
        price: null,
        quantity: 1,
        name: "",
      });
    },
    addShowProduct() {
      this.form.showProducts.push({
        id: null,
        category_id: null,
        price: null,
        quantity: 1,
        name: "",
      });
    },
    clearFormFields() {
      this.form.credit_months = null;
      this.form.credit_amount = null;
      this.monthly_payment = null;
      this.form.in_advance_payment_amount = null;
    },
    deleteProduct(index) {
      this.clearProductsArray();
      if (this.form.showProducts.length == 1 && index === 0) {
        this.form.products = [
          {
            id: null,
            category_id: null,
            price: null,
            quantity: 1,
            name: "",
          },
        ];
        this.form.showProducts = [
          {
            id: null,
            category_id: null,
            price: null,
            quantity: 1,
            name: "",
          },
        ];
      } else {
        const indexShP = this.form.showProducts.findIndex(
          (el) => el.id == this.form.showProducts[index].id
        );
        const filteredProducts = this.form.products.filter(
          (el) => el.category_id == this.form.showProducts[index].category_id
        );
        this.form.showProducts.splice(indexShP, 1);
        if (filteredProducts && filteredProducts.length) {
          filteredProducts.forEach((el) => {
            const indexP = this.form.products.findIndex(
              (product) => product.id == this.form.products[el.id].id
            );
            this.form.products.splice(indexP, 1);
          });
        }
      }
    },
    changeEndDay(day) {
      const d = new Date();
      d.setMonth(d.getMonth() + Number(this.form.credit_months));
      d.setDate(day);
      const eDate = d.toLocaleDateString();
      if (eDate.search("/") > 0) {
        this.form.end_date = `${eDate.split("/")[2]}-${eDate
          .split("/")[0]
          .padStart(2, "0")}-${eDate.split("/")[1].padStart(2, "0")}`;
      } else {
        this.form.end_date = eDate.split(".").reverse().join("-");
      }
    },
    calcEndDate() {
      const d = new Date();
      d.setMonth(d.getMonth() + Number(this.form.credit_months));
      const dM = this.form.credit_payment_day
        ? this.form.credit_payment_day
        : 15;
      d.setDate(dM);
      return d.toLocaleDateString();
    },
    getProduct(id) {
      let prodObj = null;
      if (id && this.products && this.products.data.length) {
        this.products.data.forEach((product) => {
          if (product.id === id) {
            prodObj = product;
          }
        });
      }
      return prodObj;
    },
    calcLimitCredit() {
      let limit = "";
      if (
        this.clientReport &&
        this.form.credit_months &&
        this.clientReport.limit_with_co_borrower
      ) {
        limit =
          this.form.credit_months * this.clientReport.limit_with_co_borrower;
      } else if (
        this.clientReport &&
        this.form.credit_months &&
        this.clientReport.limit
      ) {
        limit = this.form.credit_months * this.clientReport.limit;
      } else if (this.clientReport && this.form.credit_months) {
        let oneLimit = "";
        this.fetchSettings().then(() => {
          oneLimit = this.settings.data.filter(
            (c) => c.code == "credit_limit_for_one_contract"
          )[0].value;
        });
        limit = this.form.credit_months * oneLimit;
      }
      this.totalLimit = limit;
      if (limit) {
        return `(Limit: ${this.$options.filters.formatPrice(limit)} so'm)`;
      }
      return "";
    },
    getTotalProduct() {
      let total = 0;
      this.form.products.forEach((el) => {
        total += el.quantity * el.price;
      });
      return total;
    },
    addProductShow() {
      this.show_add_product = true;
      if (
        this.form.products[this.form.products.length - 1].barcode &&
        this.form.products[this.form.products.length - 1].barcode.length == 12
      ) {
        this.addProduct();
      }
      setTimeout(() => {
        this.$refs.barcode[this.form.products.length - 1].focus();
      }, 100);
    },
    getProductByBarcode(barcode, index) {
      if (barcode && barcode.length === 12) {
        this.barcodeInputLoading = true;
        this.getWarehouseProducts({ barcode })
          .then((res) => {
            this.setShowProduct(barcode, index, res);
            const product = this.form.products[index];
            const hasProduct = this.form.products.find(
              (el) => el.id === res.data.id
            );
            const hasProductIndex = hasProduct
              ? this.form.products.indexOf(hasProduct)
              : null;
            if (
              hasProduct &&
              this.form.products.length > 1 &&
              this.form.products.length - 1 == index
            ) {
              this.form.products[hasProductIndex].quantity += 1;
            } else if (product && this.form.products.length - 1 == index) {
              product.id = res.data.id;
              product.category_id = res.data.category_id;
              product.name = res.data.category.name_uz;
              product.price = res.data.price ? res.data.price : res.data.cost;
            }
            if (this.form.products.length - 1 == index) {
              this.addProduct();
            }
            this.$refs.barcode[0].focus();
          })
          .finally(() => {
            this.barcodeInputLoading = false;
          });
      } else if (barcode && barcode.length > 12) {
        this.form.products[index].barcode = Number(
          String(barcode).slice(0, 12)
        );
      }
    },
    setShowProduct(barcode, index, res) {
      if (barcode && barcode.length === 12) {
        const product = this.form.showProducts[index];
        const hasProduct = this.form.showProducts.find(
          (el) => el.category_id === res.data.category_id
        );
        const hasProductIndex = hasProduct
          ? this.form.showProducts.indexOf(hasProduct)
          : null;
        if (
          hasProduct &&
          this.form.showProducts.length > 1 &&
          this.form.showProducts.length - 1 == index
        ) {
          this.form.showProducts[hasProductIndex].quantity += 1;
        } else if (product && this.form.showProducts.length - 1 == index) {
          product.id = res.data.id;
          product.category_id = res.data.category_id;
          product.name = res.data.category.name_uz;
          product.price = res.data.price ? res.data.price : res.data.cost;
        }
        if (this.form.showProducts.length - 1 == index) {
          this.addShowProduct();
        }
      }
    },
    clearProductsArray() {
      if (this.form.products.length > 1) {
        this.form.products = this.form.products.filter((el) => el.id != null);
      }
      if (this.form.showProducts.length > 1) {
        this.form.showProducts = this.form.showProducts.filter(
          (el) => el.id != null
        );
      }
    },
    ...mapActions({
      store: "contract/store",
      update: "contract/update",
      getProductsAction: "product/index",
      getItemsAction: "productCategory/tree",
      getWarehouseProducts: "productCategory/warehouseProductsByBarcode",
      fileUpload: "client/fileUpload",
      fetchSettings: "setting/index",
      calculateCreditAmount: "resource/calculateCreditAmount",
    }),
  },
};
</script>

<style scoped>
</style>
