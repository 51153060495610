<template>
  <div id="responce-show">
    <div
      v-if="clientReport && !report_loading"
    >
      <!-- class="d-flex flex-row flex-wrap justify-content-around" -->

      <!-- mib -->
      <div
        v-if="clientReport.mib_report_id"
        :class="clientReport.mib_result?'success-block':'danger-block'"
      >
        <div class="header-block">
          <span>MIB natijasi</span>
          <span
            class="reload"
            @click="getReport('mib')"
          >
            <feather-icon
              class="float-right mr-1"
              color="#7367F0"
              icon="RotateCwIcon"
              size="20"
            />
          </span>
        </div>

        <div class="mt-2">
          <b>MIB natijasi:</b>
          <span class="float-right">
            <template v-if="clientReport.mib_result">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div class="mt-2">
          <b>MIB qarzdorligi:</b>
          <span class="float-right">
            <template v-if="!clientReport.mib_indebtedness">
              <span class="font-weight-bolder text-success">0 so'm</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">{{ clientReport.mib_indebtedness|formatPrice }}</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
      </div>
      <div
        v-else
        class="r-title-block"
        @click="getReport('mib')"
      >
        <span>MIB natijasini olish</span>
        <span
          class="reload"
        >
          <feather-icon
            class="float-right mr-1"
            color="#7367F0"
            icon="RotateCwIcon"
            size="20"
          />
        </span>
      </div>

      <!-- card -->
      <div
        v-if="clientReport.card_report_id"
        :class="clientReport.mib_result?'success-block':'danger-block'"
      >
        <div class="header-block">
          <span>Karta bo'yicha natija</span>
          <span
            class="reload"
            @click="getReport('card')"
          >
            <feather-icon
              class="float-right mr-1"
              color="#7367F0"
              icon="RotateCwIcon"
              size="20"
            />
          </span>
        </div>
        <div class="mt-2">
          <b>Karta ma'lumotlari bo'yicha natija:</b>
          <span class="float-right">
            <template v-if="clientReport.card_result">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div class="mt-2">
          <b>O'rtacha oylik ish haqi:</b>
          <span class="float-right">
            <template v-if="clientReport.card_average_salary && clientReport.card_is_greater_mrzp">
              <span class="font-weight-bolder text-success">{{ clientReport.card_average_salary | formatPrice }} so'm</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">{{ clientReport.card_average_salary | formatPrice }}</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div class="mt-2">
          <b>Oylik uzluksizligi:</b>
          <span class="float-right">
            <template v-if="clientReport.card_is_received_continuous_salary">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div class="mt-2">
          <b>O'rtacha oylik MHTEKMdan ko'pligi:</b>
          <span class="float-right">
            <template v-if="clientReport.card_is_greater_mrzp">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <!-- footer -->
        <div
          v-if="clientReport.card_report"
          class="footer-block"
        >
          <span @click="showReport('card')">
            Отчёт по Карте
          </span>
          <template v-if="show_report=='card'">
            <card-report
              v-if="clientReport.card_report&& clientReport.card_report.result_data.report.result"
              :visible="show_report=='card'"
              :report="clientReport.card_report.result_data.report.result.CountInfos"
              @close="show_report=null"
            />
          </template>
        </div>
      </div>
      <div
        v-else-if="clientReport.report_strategy_id==2"
        class="r-title-block"
        @click="getReport('card')"
      >
        <span>Karta bo'yicha natijani olish</span>
        <span
          class="reload"
        >
          <feather-icon
            class="float-right mr-1"
            color="#7367F0"
            icon="RotateCwIcon"
            size="20"
          />
        </span>
      </div>

      <!-- inps -->
      <div
        v-if="clientReport.inps_report_id && clientReport.inps_report && clientReport.inps_report.result_data"
        :class="clientReport.inps_result?'success-block':'danger-block'"
      >
        <div class="header-block">
          <span>INPS natijasi</span>
          <span
            class="reload"
            @click="getReport('inps')"
          >
            <feather-icon
              class="float-right mr-1"
              color="#7367F0"
              icon="RotateCwIcon"
              size="20"
            />
          </span>
        </div>
        <div class="mt-2">
          <b>INPS natijasi:</b>
          <span class="float-right">
            <template v-if="clientReport.inps_result">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div class="mt-2">
          <b>O'rtacha oylik ish haqi:</b>
          <span class="float-right">
            <template v-if="clientReport.inps_average_salary && clientReport.inps_is_greater_mrzp">
              <span class="font-weight-bolder text-success">{{ clientReport.inps_average_salary | formatPrice }} so'm</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">{{ clientReport.inps_average_salary }}</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div class="mt-2">
          <b>Oylik uzluksizligi:</b>
          <span class="float-right">
            <template v-if="clientReport.inps_is_received_continuous_salary">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div class="mt-2">
          <b>O'rtacha oylik MHTEKMdan ko'pligi:</b>
          <span class="float-right">
            <template v-if="clientReport.inps_is_greater_mrzp">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <!-- footer -->
        <div
          v-if="clientReport.inps_report&& clientReport.inps_report.report_code=='048'"
          class="footer-block"
        >
          <span @click="showReport('inps')">
            Заработная плата по ГНК
          </span>
          <template v-if="show_report=='inps'">
            <gnk-report
              v-if="clientReport.inps_report.result_data.report.data&& clientReport.inps_report.result_data.report.data.length"
              :visible="show_report=='inps'"
              :items="clientReport.inps_report.result_data.report.data"
              @close="show_report=null"
            />
          </template>
        </div>
        <div
          v-else-if="clientReport.inps_report"
          class="footer-block"
        >
          <span @click="showReport('inps')">
            Заработная плата по ИНПС
          </span>
          <template v-if="show_report=='inps'">
            <inps-report
              v-if="clientReport.inps_report"
              :visible="show_report=='inps'"
              :items="inpsReportItems(clientReport)"
              @close="show_report=null"
            />
          </template>
        </div>
      </div>
      <div
        v-else-if="clientReport.report_strategy_id==1"
        class="r-title-block"
        @click="getReport('inps')"
      >
        <span>INPS natijasini olish</span>
        <span
          class="reload"
        >
          <feather-icon
            class="float-right mr-1"
            color="#7367F0"
            icon="RotateCwIcon"
            size="20"
          />
        </span>
      </div>

      <!-- start -->
      <div
        v-if="clientReport.start_report_id"
        :class="clientReport.start_result?'success-block':'danger-block'"
      >
        <div class="header-block">
          <span>Joriy kredit natijasi</span>
          <span
            class="reload"
            @click="getReport('start')"
          >
            <feather-icon
              class="float-right mr-1"
              color="#7367F0"
              icon="RotateCwIcon"
              size="20"
            />
          </span>
        </div>
        <div class="mt-2">
          <b>Joriy kreditlari bo'yicha:</b>
          <span class="float-right">
            <template v-if="clientReport.start_result">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <span
                v-if="!clientReport.start_credit_monthly_summ"
                class="font-weight-bolder text-success"
              > (Kredit mavjud emas)</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div
          v-if="clientReport.start_credit_monthly_summ"
          class="mt-2"
        >
          <b>Oylik kredit summasi:</b>
          <span class="float-right">
            <template v-if="clientReport.start_is_average_salary_minus_start_greater_mrzp">
              <span class="font-weight-bolder text-success">{{ clientReport.start_credit_monthly_summ |formatPrice }} so'm</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">{{ clientReport.start_credit_monthly_summ |formatPrice }} so'm</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div
          class="mt-2"
        >
          <b>Yangi kredit olishga yaroqliligi:</b>
          <span class="float-right">
            <template v-if="clientReport.start_is_average_salary_minus_start_greater_mrzp">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div
          v-if="clientReport.start_overdue_indebtedness_summ"
          class="mt-2"
        >
          <b>Kredit qarzdorligi:</b>
          <span class="float-right">
            <template v-if="!clientReport.start_is_overdue_indebtedness_greater">
              <span class="font-weight-bolder text-success">{{ clientReport.start_overdue_indebtedness_summ |formatPrice }} so'm</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Muddati o'tgan qarzdorligi chegaradan ko'p</span> <br>
              <span class="font-weight-bolder text-danger">{{ clientReport.start_overdue_indebtedness_summ |formatPrice }} so'm</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div
          class="mt-2"
        >
          <b>Kreditlar soni limitdan kamligi:</b>
          <template v-if="clientReport.start_is_credit_counts_smaller_limit_credit_counts">
            <span class="float-right">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </span>
          </template>
          <template v-else>
            <span class="float-right">
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </span>
          </template>
        </div>
        <!--footer-->
        <div
          v-if="clientReport.start_credit_monthly_summ"
          class="footer-block"
        >
          <span @click="showReport('start')">
            Joriy kredit bo'yicha hisobot
          </span>
          <start-report
            v-if="clientReport.start_report&& clientReport.start_report.result_data.report23.open_contracts.all_debt_sum"
            :visible="show_report=='start'"
            :report="clientReport.start_report.result_data.report23.open_contracts.open_contract"
            @close="show_report=null"
          />
        </div>
      </div>
      <div
        v-else
        class="r-title-block"
        @click="getReport('start')"
      >
        <span>Joriy kredit natijasini olish</span>
        <span
          class="reload"
        >
          <feather-icon
            class="float-right mr-1"
            color="#7367F0"
            icon="RotateCwIcon"
            size="20"
          />
        </span>
      </div>

      <!-- scoring -->
      <div
        v-if="clientReport.scoring_report_id"
        :class="!clientReport.scoring_is_scoring_value_smaller?'success-block':'danger-block'"
      >
        <div class="header-block">
          <span>Scoring natijasi</span>
          <span
            class="reload"
            @click="getReport('scoring')"
          >
            <feather-icon
              class="float-right mr-1"
              color="#7367F0"
              icon="RotateCwIcon"
              size="20"
            />
          </span>
        </div>
        <div class="mt-2">
          <b>Kreditlari scoring bali:</b>
          <span class="float-right">
            <template v-if="!clientReport.scoring_is_scoring_value_smaller">
              <span class="font-weight-bolder text-success">{{ clientReport.scoring_scoring_value }} (Qoniqarli)</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">{{ clientReport.scoring_scoring_value }} (Qoniqarsiz)</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div
          class="mt-2"
        >
          <b>Kredit qarzdorligi 90 kundan ortiq:</b>
          <span class="float-right">
            <template v-if="clientReport.scoring_is_overdue_indebtedness_greater_days">
              <span class="font-weight-bolder text-danger">O'tgan</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-success">O'tmagan</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
          </span>
        </div>

        <div
          v-if="clientReport.scoring_report_id"
          class="footer-block"
        >
          <span @click="showReport('scoring')">
            Кредитный отчёт "СКОРИНГ КИАЦ"
          </span>
          <scoring-report
            v-if="clientReport.scoring_report"
            :visible="show_report=='scoring'"
            :report="clientReport.scoring_report.result_data.report.additional_info"
            @close="show_report=null"
          />
        </div>
      </div>
      <div
        v-else
        class="r-title-block"
        @click="getReport('scoring')"
      >
        <span>Scoring natijasini olish</span>
        <span
          class="reload"
        >
          <feather-icon
            class="float-right mr-1"
            color="#7367F0"
            icon="RotateCwIcon"
            size="20"
          />
        </span>
      </div>

      <!-- pension -->
      <div
        v-if="clientReport.pension_report_id"
        :class="clientReport.pension_result?'success-block':'danger-block'"
      >
        <div class="header-block">
          <span>Pensiya natijasi</span>
          <span
            class="reload"
            @click="getReport('pension')"
          >
            <feather-icon
              class="float-right mr-1"
              color="#7367F0"
              icon="RotateCwIcon"
              size="20"
            />
          </span>
        </div>
        <div class="mt-2">
          <b>Pensiya natijasi:</b>
          <span class="float-right">
            <template v-if="clientReport.pension_result">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div class="mt-2">
          <b>O'rtacha oylik pensiyasi:</b>
          <span class="float-right">
            <template v-if="clientReport.pension_average_salary && clientReport.pension_is_greater_mrzp">
              <span class="font-weight-bolder text-success">{{ clientReport.pension_average_salary | formatPrice }} so'm</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">{{ clientReport.pension_average_salary|formatPrice }} so'm</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div
          v-if="!clientReport.pension_is_smaller_age"
          class="mt-2"
        >
          <b>Yosh chegarasi:</b>
          <span class="float-right">
            <span class="font-weight-bolder text-danger">70 yoshdan katta</span>
            <feather-icon
              color="red"
              icon="XIcon"
              size="20"
            />
          </span>
        </div>
        <div class="mt-2">
          <b>Pensiya uzluksizligi:</b>
          <span class="float-right">
            <template v-if="clientReport.pension_is_received_continuous_salary">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <div class="mt-2">
          <b>Pensiya oylik MHTEKMdan ko'pligi:</b>
          <span class="float-right">
            <template v-if="clientReport.pension_is_greater_mrzp">
              <span class="font-weight-bolder text-success">Qoniqarli</span>
              <feather-icon
                class="ml-1"
                color="green"
                icon="CheckCircleIcon"
                size="20"
              />
            </template>
            <template v-else>
              <span class="font-weight-bolder text-danger">Qoniqarsiz</span>
              <feather-icon
                color="red"
                icon="XIcon"
                size="20"
              />
            </template>
          </span>
        </div>
        <!-- footer -->
        <div
          v-if="clientReport.pension_average_salary"
          class="footer-block"
        >
          <span @click="showReport('pension')">
            Pensiya hisoboti
          </span>
          <template v-if="show_report=='pension'">
            <pension-report
              v-if="clientReport.pension_report&& clientReport.pension_report.result_data.report.sizepension"
              :visible="show_report=='pension'"
              :items="clientReport.pension_report.result_data.report.sizepension"
              @close="show_report=null"
            />
          </template>
        </div>
      </div>
      <div
        v-else-if="clientReport.report_strategy_id==3"
        class="r-title-block"
        @click="getReport('pension')"
      >
        <span>Pensiya natijasini olish</span>
        <span
          class="reload"
        >
          <feather-icon
            class="float-right mr-1"
            color="#7367F0"
            icon="RotateCwIcon"
            size="20"
          />
        </span>
      </div>

      <!-- additional -->
      <div
        v-if="clientReport.monthly_average_salary"
        class="result-limit-block"
      >
        <div
          class="mt-2"
        >
          <b class="mr-2">O'rtacha oylik ish haqi: </b>
          <span class="float-right">
            <span class="font-weight-bolder text-success"> {{ clientReport.monthly_average_salary|formatPrice }} so'm</span>
          </span>
        </div>
        <div
          v-if="clientReport.limit"
          class="mt-2"
        >
          <b class="mr-2">Limit: </b>
          <span class="float-right">
            <span class="font-weight-bolder text-success"> {{ clientReport.limit|formatPrice }} so'm</span>
          </span>
        </div>
      </div>

    </div>
    <div
      v-else
      style="text-align:center;padding:50%"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InpsReport from './inps_report.vue'
import GnkReport from './gnk_report.vue'
import StartReport from './start_report.vue'
import CardReport from './card_report.vue'
import ScoringReport from './scoring_report.vue'
import PensionReport from './pension_report.vue'

export default {
  components: {
    InpsReport, StartReport, ScoringReport, PensionReport, CardReport, GnkReport,
  },
  props: {
    clientReport: {
      type: Object,
      default: () => null,
    },
    form: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      showinpsReport: false,
      showstartReport: false,
      showscoringReport: false,
      show_report: null,
      report_loading: false,
      // reports: {
      //   inps_report: null,
      //   start_report: null,
      //   scoring_report: null,
      // },
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapActions({ makeReportPart: 'resource/makeReportPart' }),
    getReport(type) {
      const data = {
        client_report_id: this.clientReport.id,
        report_code: type,
        type: this.type,
      }
      if (type === 'card') {
        data.card_number4 = this.form.card_number4
      }
      this.report_loading = true
      this.makeReportPart(data).then(res => {
        if (res.success) {
          this.report_loading = false
        }
      })
        .finally(() => { this.report_loading = false })
    },
    showReport(type) {
      this.show_report = type
    },
    inpsReportItems(clientReport) {
      if (clientReport && clientReport.inps_report.result_data.report.incomes.INCOME) {
        return clientReport.inps_report.result_data.report.incomes.INCOME[0]
          ? clientReport.inps_report.result_data.report.incomes.INCOME
          : [clientReport.inps_report.result_data.report.incomes.INCOME]
      } if (clientReport && clientReport.inps_report.result_data.report.incomes.income) {
        return clientReport.inps_report.result_data.report.incomes.income[0]
          ? clientReport.inps_report.result_data.report.incomes.income
          : [clientReport.inps_report.result_data.report.incomes.income]
      }
      return clientReport.inps_report.result_data.report.incomes.income
    },
  },
}
</script>
