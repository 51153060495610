<template>
  <div>
    <template v-if="isClientSelfCash">
      <h4 class="text-center">
        {{ [client_data.lastname, client_data.firstname, client_data.middlename].join(' ') }} bizing do'konimizdan mahsulot sotib olgan
      </h4>
      <div class="primary-block">
        <div class="mt-2">
          <b>Kreditlar soni:</b>
          <span class="float-right">
            <router-link
              class="font-weight-bolder text-primary link"
              :to="{ name: 'OrdersIndex', query: {pin: selfShopInfo.selfShopReportForClient.result_data.pin} }"
              target="_blank"
            >{{ selfShopInfo.selfShopReportForClient.result_data.count_credits_from_self_shop }}
            </router-link>
          </span>
        </div>
        <div class="mt-2">
          <b>Oylik to'lovi:</b>
          <span class="float-right">
            <span class="font-weight-bolder text-success">{{ selfShopInfo.selfShopReportForClient.result_data.monthly_average_payment_summa }}</span>
          </span>
        </div>
      </div>
    </template>
    <template v-if="isClient2SelfCash">
      <hr>
      <h4 class="text-center">
        {{ [client2_data.lastname, client2_data.firstname, client2_data.middlename].join(' ') }} bizing do'konimizdan mahsulot sotib olgan
      </h4>
      <div class="primary-block">
        <div class="mt-2">
          <b>Kreditlar soni:</b>
          <span class="float-right">
            <span class="font-weight-bolder text-success">{{ selfShopInfo.selfShopReportForClient2.result_data.count_credits_from_self_shop }}</span>
          </span>
        </div>
        <div class="mt-2">
          <b>Oylik to'lovi:</b>
          <span class="float-right">
            <span class="font-weight-bolder text-success">{{ selfShopInfo.selfShopReportForClient2.result_data.monthly_average_payment_summa }}</span>
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    selfShopInfo: {
      type: Object,
      default: () => {},
    },
    client_data: {
      type: Object,
      default: () => null,
    },
    client2_data: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      clientReport: 'resource/CLIENT_REPORT',
      client2Report: 'resource/CLIENT2_REPORT',
      clientsReport: 'resource/CLIENTS_REPORT',
    }),
    isClientSelfCash() {
      if (this.selfShopInfo && this.selfShopInfo.selfShopReportForClient && this.selfShopInfo.selfShopReportForClient.result_data.result_from_self_shop == 0) {
        return true
      }
      return false
    },
    isClient2SelfCash() {
      if (this.selfShopInfo && this.selfShopInfo.selfShopReportForClient2 && this.selfShopInfo.selfShopReportForClient2.result_data.result_from_self_shop == 0) {
        return true
      }
      return false
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>
