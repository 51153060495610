<template>
  <div>
    <b-overlay :show="form_loading">
      <validation-observer
        ref="form"
        #default="{ invalid }"
      >
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="validationForm"
        >
          <b-button
            v-if="!show_add_product"
            variant="outline-primary"
            class="btn-tour-skip mb-2"
            @click="addProductShow"
          >
            <span class="mr-25 align-middle">
              <feather-icon icon="PlusIcon" />
              {{ $t("Добавить продукт") }}</span>
          </b-button>

          <div v-else>
            <div
              v-for="(product, pIndex) in form.products"
              :key="pIndex"
            >
              <div
                v-if="form.products.length == pIndex + 1"
                class="row"
              >
                <b-form-group
                  :label="$t('Код товара')"
                  label-for="barcode"
                  class="col-6"
                >
                  <b-overlay :show="barcodeInputLoading">
                    <b-form-input
                      id="barcode"
                      ref="barcode"
                      v-model="product.barcode"
                      name="barcode"
                      placeholder="000000000000"
                      @input="getProductByBarcode(product.barcode, pIndex)"
                    />
                  </b-overlay>
                </b-form-group>

                <div class="col-2">
                  <span class="fs20">
                    <feather-icon
                      class="mt-2"
                      color="#FF0000"
                      icon="XIcon"
                      @click="show_add_product = false"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.products && form.products[0].id">
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Nomi</th>
                  <th>Soni</th>
                  <th v-if="hasMobileProduct">
                    IMEI
                  </th>
                  <th>Narxi</th>
                  <th>Jami</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <template v-for="(product, index) in form.products">
                  <tr
                    v-if="product.id"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td
                      v-if="product.id"
                      class="text-primary"
                    >
                      {{ product.name }}
                      <div
                        v-if="product.i_cloud"
                        class="d-block"
                      >
                        <p>
                          iCloud:
                          <b class="text-primary">
                            {{ product.i_cloud.mail }}
                          </b>
                        </p>
                      </div>
                      <div
                        v-else
                        class="d-block"
                      >
                        <b-button
                          size="sm"
                          variant="outline-primary"
                          class="btn-tour-skip mt-1"
                          @click="openICloudModal(product)"
                        >
                          <span class="align-middle">iCloud biriktirish</span>
                          <feather-icon
                            icon="CloudIcon"
                            size="12"
                          />
                        </b-button>
                      </div>
                    </td>
                    <td>{{ product.quantity }} dona</td>
                    <td v-if="hasMobileProduct">
                      <p v-if="product.imei_1">
                        <b>IMEI 1: </b> {{ product.imei_1 }}
                      </p>
                      <p v-if="product.imei_2">
                        <b>IMEI 2: </b> {{ product.imei_2 }}
                      </p>
                    </td>
                    <td v-if="product.id">
                      {{ product.price | formatPrice }}so'm
                    </td>
                    <td v-if="product.id">
                      {{ (product.quantity * product.price) | formatPrice }}so'm
                    </td>
                    <td>
                      <feather-icon
                        color="#FF0000"
                        icon="TrashIcon"
                        @click="deleteProduct(index)"
                      />
                    </td>
                  </tr>
                </template>
                <!-- <tr>
                  <th colspan="2">Jami</th>
                  <th colspan="4">{{ getTotalProduct() | formatPrice }}so'm</th>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div
            v-if="
              showProductsList && showProductsList[0] && showProductsList[0].id
            "
            class="primary-block"
          >
            <div class="mb-2">
              <i>Состав заказа</i>
            </div>

            <div
              v-for="(product, i) in showProductsList"
              :key="i"
              class="mb-2"
            >
              <template v-if="product.id">
                <h5 class="font-medium-1">
                  {{ product.name }}
                </h5>
                <div class="d-flex justify-content-between">
                  <span class="">
                    {{ product.price | formatPrice }} so'm
                  </span>
                  <span class="">
                    <b>x</b>
                    {{ product.quantity }} dona
                  </span>
                </div>
              </template>
            </div>
            <div class="flex justify-content-between">
              <span class="mb-2 font-weight-bolder"> Итого: </span>
              <span class="font-weight-bolder">{{ getTotalProduct() | formatPrice }} so'm</span>
            </div>
          </div>

          <hr>
          <div class="row">
            <div class="col-12 col-sm-6">
              <b-form-group
                :label="$t('Срок рассрочки (месяц)')"
                label-for="credit_months"
              >
                <validation-provider
                  #default="{ errors }"
                  name="credit_months"
                  rules="required"
                >
                  <b-form-input
                    id="credit_months"
                    v-model="form.credit_months"
                    v-mask="'##'"
                    :disabled="!form.products[0].id"
                    :state="errors.length > 0 ? false : null"
                    name="credit_months"
                    debounce="500"
                    placeholder="12"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-12 col-sm-6">
              <b-form-group
                label="Cумма предоплаты (so'm)"
                label-for="in_advance_payment_amount"
              >
                <cleave
                  id="in_advance_payment_amount"
                  v-model="form.in_advance_payment_amount"
                  class="form-control"
                  :options="cleaveOption"
                  :disabled="!form.products[0].id"
                  placeholder="0"
                />
                <span
                  v-if="form.in_advance_payment_amount && dollarRate"
                  class="mt-1"
                >{{
                  (form.in_advance_payment_amount / dollarRate).toFixed(2)
                }}
                  $</span>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <b-form-group
                label="Valyutada sotilmoqdami?"
                label-for="credit_months"
              >
                <b-form-checkbox
                  v-model="form.is_dollar"
                  :value="1"
                  :unchecked-value="0"
                >
                  Valyutada
                </b-form-checkbox>
              </b-form-group>
            </div>
            <!-- <div class="col-12 col-sm-6">
              <b-form-group
                v-if="form.is_dollar"
                label-for="monthly_payment"
              >
                <span
                  slot="label"
                  class="font-weight-bolder"
                >{{
                  $t("Ежемесячный платеж")
                }}</span>
                <cleave
                  id="price"
                  v-model="form.monthly_payment_amount"
                  class="form-control"
                  :options="cleaveOption"
                  placeholder="0"
                />
              </b-form-group>
            </div> -->
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group label-for="credit_amount">
                <validation-provider
                  #default="{ errors }"
                  name="credit_amount"
                  rules="required"
                >
                  <span
                    slot="label"
                    class="font-weight-bolder"
                    :class="{ 'text-danger': totalLimit < form.credit_amount }"
                  >{{
                    $t("Сумма рассрочки") + calcLimitCredit() }}</span>
                  <cleave
                    id="price"
                    v-model="form.credit_amount"
                    class="form-control"
                    :options="cleaveOption"
                    disabled
                    :state="errors.length > 0 ? false : null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div
              v-if="monthly_payment"
              class="col-12 col-md-6"
            >
              <!-- <b-form-group
                v-if="form.is_dollar"
                label="Chegirma miqdori"
                label-for="discount_amount"
              >
                <cleave
                  id="discount_amount"
                  v-model="form.discount_amount"
                  class="form-control"
                  :options="cleaveOption"
                  placeholder="0"
                  disabled
                />
              </b-form-group> -->
              <!-- v-else -->
              <b-form-group
                label-for="monthly_payment"
              >
                <span
                  slot="label"
                  class="font-weight-bolder"
                >{{
                  $t("Ежемесячный платеж")
                }}</span>
                <cleave
                  id="price"
                  v-model="monthly_payment"
                  class="form-control"
                  :options="cleaveOption"
                  disabled
                  placeholder="0"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group
                v-if="form.start_date"
                :label="$t('Дата начала')"
                label-for="start_date"
              >
                {{ form.start_date.split("-").reverse().join(".") }}
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group
                :label="$t('Дата окончания')"
                label-for="end_date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="end_date"
                  rules="required"
                >
                  <x-date-picker
                    id="end_date"
                    v-model="form.end_date"
                    :state="errors.length > 0 ? false : null"
                    name="end_date"
                    disabled
                    :placeholder="$t('Дата окончания')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group
                :label="$t('Дата оплаты')"
                label-for="credit_payment_day"
              >
                <validation-provider
                  #default="{ errors }"
                  name="credit_payment_day"
                  rules="required"
                >
                  <b-form-select
                    id="from_warehouse_id"
                    v-model="form.credit_payment_day"
                    :placeholder="$t('Дата оплаты')"
                    :options="endDays"
                    @change="changeEndDay(form.credit_payment_day)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <hr>
          <h4 class="card-title">
            {{ $t("Контакты близких") }}
          </h4>
          <p>{{ $t("Пожалуйста, укажите контакты близких Вам людей.") }}</p>
          <b-row>
            <b-col md="6">
              <h3 class="card-title">
                {{ $t("Контактное лицо - 1") }}
              </h3>
              <b-form-group
                :label="$t('Введите номер телефона')"
                label-for="v-phone-1"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:9"
                  name="v-phone-1"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      +998
                    </b-input-group-prepend>

                    <b-form-input
                      id="v-phone-1"
                      v-model="form.near_phone1"
                      v-mask="'#########'"
                      :state="errors.length > 0 ? false : null"
                      placeholder="90 0000000"
                      :disabled="form.is_sms_confirmed"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <h3 class="card-title">
                {{ $t("Контактное лицо - 2") }}
              </h3>
              <b-form-group
                :label="$t('Введите номер телефона')"
                label-for="v-phone-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="v-phone-2"
                  rules="required|min:9"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      +998
                    </b-input-group-prepend>
                    <b-form-input
                      id="v-phone-2"
                      v-model="form.near_phone2"
                      v-mask="'#########'"
                      placeholder="900000000"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="isPhoneFull && !is_sent_sms"
              md="3"
            >
              <b-button
                type="submit"
                variant="primary"
                class="mt-2"
                @click="sendSms"
              >
                {{ $t("Получить смс") }}
              </b-button>
            </b-col>
            <template v-if="is_sent_sms && !form.is_sms_confirmed">
              <b-col md="4">
                <b-form-group
                  :label="$t('Введите код из СМС')"
                  label-for="sms"
                >
                  <b-form-input
                    id="sms"
                    v-model="form.code"
                    v-mask="'####'"
                    type="number"
                    placeholder="0000"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-button
                  v-if="form.code && form.code.length > 3"
                  type="submit"
                  variant="primary"
                  class="mt-2"
                  @click="checkSms"
                >
                  {{ $t("Отправить") }}
                </b-button>
              </b-col>
            </template>
            <!--  -->
            <b-col
              class="mt-3"
              md="12"
            >
              <b-form-checkbox
                v-model="form.is_online"
                :value="1"
                :unchecked-value="0"
              >
                Onlayn shartnoma (telefon orqali)
              </b-form-checkbox>
            </b-col>
          </b-row>
          <hr>
          <!--  -->
          <div class="text-right mt-3">
            <b-button
              variant="primary"
              class="btn-tour-skip"
              @click="save"
            >
              <span class="mr-25 align-middle">Shartnoma yaratish</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>
    <div v-if="icloudModal">
      <icloud-attach-modal
        :contract-product-id="attachForm.contract_product_id"
        :not-attach="true"
        @close="closeCloudModal"
      />
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import 'vue-tree-halower/dist/halower-tree.min.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'debounce'
import 'cleave.js/dist/addons/cleave-phone.us'
import { getItem } from '@/utils/storage'

import icloudAttachModal from '@/components/modals/icloudAttach.vue'
import ChooseProduct from './choose_product.vue'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
    ChooseProduct,
    icloudAttachModal,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show_add_product: false,
      barcodeInputLoading: false,
      totalLimit: null,
      monthly_payment: null,
      oneLimit: 0,
      icloudModal: false,
      form: {
        products: [
          {
            id: null,
            category_id: null,
            price: null,
            quantity: 1,
            name: '',
            imei_1: null,
            imei_2: null,
          },
        ],
        is_dollar: 0,
        is_online: 0,
        // monthly_payment_amount: null,
        discount_amount: null,
        credit_payment_day: null,
        type: null,
        client_id: null,
        client_report_id: null,
        client2_id: null,
        client2_report_id: null,

        in_advance_payment_amount: null,
        start_date: null,
        end_date: null,
        credit_amount: null,
        credit_months: null,

        relative_first_name: null,
        relative_second_name: null,
        near_phone1: null,
        near_phone2: null,
        code: null,
        is_sms_confirmed: false,
      },
      attachForm: {
        contract_product_id: null,
      },
      is_sent_sms: false,
      endDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      loading: false,
      form_loading: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      products: 'product/GET_ITEMS',
      clientReport: 'resource/CLIENT_REPORT',
      settings: 'setting/GET_ITEMS',
      client2Report: 'resource/CLIENT2_REPORT',
    }),

    showProductsList() {
      const mergedArray = this.form.products.reduce((acc, item) => {
        const existing = acc.find(el => el.category_id === item.category_id)
        if (existing) {
          existing.quantity += item.quantity
        } else {
          acc.push({ ...item })
        }
        return acc
      }, [])
      return mergedArray
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    hasMobileProduct() {
      return this.form.products.some(e => e.imei_1)
    },
    today() {
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      return `${yyyy}-${mm}-${dd}`
    },
    isPhoneFull() {
      return this.form.near_phone1 && this.form.near_phone1.length >= 7
    },
    dollarRate() {
      const rate = getItem('realDollarRate')
      return Number(rate)
    },
  },
  watch: {
    isPhoneFull(val) {
      if (!val) {
        this.is_sent_sms = false
      }
    },
    'form.credit_months': function (val) {
      if (val && this.form.products.length) {
        this.getCreditAmountDebounce()
        // this.getCreditAmount()
      }
    },
    'form.in_advance_payment_amount': function (val, oldVal) {
      if (val && this.form.products.length) {
        this.getCreditAmountDebounce()
      }
    },
    // 'form.monthly_payment_amount': function (val, oldVal) {
    //   if (val && this.form.products.length) {
    //     this.getCalculateDiscount()
    //   }
    // },
    'form.near_phone2': function (val) {
      if (val && val.length > 8 && val == this.form.near_phone1) {
        showToast(
          'danger',
          this.$t("Telefon raqamlar teng bo'lishi mumkin emas"),
          'XIcon',
        )
        this.form.near_phone2 = ''
      }
    },
    // 'form.is_dollar': function (val) {
      // if (val) {
      // this.form.discount_amount = null
      // this.form.credit_amount = null
      // this.form.monthly_payment_amount = null
      // this.form.credit_months = null
      // }
    // },
  },
  mounted() {
    this.form.start_date = this.today
    this.fetchSettings().then(() => {
      this.form.credit_payment_day = this.settings.data.filter(
        c => c.code == 'credit_payment_day',
      )[0].value
      this.oneLimit = this.settings.data.filter(
        c => c.code == 'credit_limit_for_one_contract',
      )[0].value
    })
    this.getProductsAction()
    this.setForm()
  },
  methods: {
    sendSms() {
      this.sendSmsAction({ phone: this.form.near_phone1 }).then(() => {
        this.is_sent_sms = true
      })
    },
    checkSms() {
      this.confirmSms({ phone: this.form.near_phone1, code: this.form.code })
        .then(res => {
          if (res.success) {
            this.form.is_sms_confirmed = true
          }
        })
        .catch(err => {
          showToast('danger', err.data.message, 'XIcon')
        })
    },
    setForm() {
      if (this.formData) {
        this.form.type = this.formData.contract_type
        if (this.client2Report && this.client2Report.id) {
          this.form.client2_report_id = this.client2Report.id
          this.form.client2_id = this.client2Report.client_id
        }
        if (this.clientReport && this.clientReport.id) {
          this.form.client_report_id = this.clientReport.id
          this.form.client_id = this.clientReport.client_id
        }
      }
    },
    async save() {
      this.setForm()
      const valid = await this.validationForm()
      this.clearProductsArray()
      if (valid) {
        if (this.form.is_sms_confirmed) {
          // if (this.form.is_dollar) {
          //   if (Number(this.form.discount_amount) > 0) {
          //     this.saveActionFn()
          //   } else {
          //     showToast(
          //       'warning',
          //       this.$t("Chegirma miqdori manfiy bo'lishi mumkin emas"),
          //     )
          //   }
          // } else {
            this.saveActionFn()
          // }
        } else {
          showToast(
            'warning',
            this.$t('Контактное лицо - 1 raqamini sms orqali tasdiqlang'),
          )
        }
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    saveActionFn() {
      this.form.near_phone1 = this.form.near_phone1.replace(/\s/g, '')
      this.form.near_phone2 = this.form.near_phone2.replace(/\s/g, '')
      this.form_loading = true
      this.store(this.form)
        .then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess', res.data)
          this.form_loading = false
        })
        .catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
          this.form_loading = false
        })
        .finally(() => {
          this.form_loading = false
        })
    },
    debounceFn(fn, delay) {
      let timer
      return (() => {
        clearTimeout(timer)
        timer = setTimeout(() => fn(), delay)
      })()
    },
    getCreditAmountDebounce: debounce(function (search) {
      this.getCreditAmount()
      // if (this.form.is_dollar) {
      //   this.calcDiscountAction()
      // }
    }, 500),
    // getCalculateDiscount: debounce(function () {
    //   this.calcDiscountAction()
    //   // this.getCreditAmount()
    // }, 500),
    // calcDiscountAction() {
    //   this.calculatediscountAmount(this.form).then(res => {
    //     if (res.success) {
    //       if (this.form.credit_months) {
    //         this.form.discount_amount = Number(res.data)
    //         this.form.credit_amount = this.form.credit_months * this.form.monthly_payment_amount
    //       } else {
    //         this.form.discount_amount = null
    //       }
    //     } else {
    //       this.form.discount_amount = null
    //     }
    //   }).catch(() => {
    //     this.form.discount_amount = null
    //   })
    // },
    async getCreditAmount() {
      this.clearProductsArray()
      await this.calculateCreditAmount(this.form).then(res => {
        this.form.credit_amount = res.data
        if (this.form.credit_months) {
          this.monthly_payment = Number(res.data) / Number(this.form.credit_months)
        } else {
          this.monthly_payment = null
        }
      })
      const eDate = this.calcEndDate()
      if (eDate.search('/') > 0) {
        this.form.end_date = `${eDate.split('/')[2]}-${eDate
          .split('/')[0]
          .padStart(2, '0')}-${eDate.split('/')[1].padStart(2, '0')}`
      } else {
        this.form.end_date = eDate.split('.').reverse().join('-')
      }
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    setProduct(data) {
      if (this.form.products[0].id) {
        this.form.products.push(data)
      } else {
        this.form.products = [data]
      }
      this.clearFormFields()
    },
    updateProduct(data) {
      if (data.index !== null) {
        this.form.products[data.index] = data
      }
      this.clearFormFields()
    },
    addProduct() {
      this.form.products.push({
        id: null,
        category_id: null,
        price: null,
        quantity: 1,
        imei_1: null,
        imei_2: null,
        name: '',
      })
    },

    clearFormFields() {
      this.form.credit_months = null
      this.form.credit_amount = null
      this.monthly_payment = null
      this.form.in_advance_payment_amount = null
    },

    deleteProduct(index) {
      this.clearProductsArray()
      if (
        this.form.products.length == 1
        && index === 0
        && this.form.products[0].quantity == 1
      ) {
        this.form.products = [
          {
            id: null,
            category_id: null,
            price: null,
            quantity: 1,
            imei_1: null,
            imei_2: null,
            name: '',
          },
        ]
        return
      }

      const productToDelete = this.form.products[index]
      const productId = productToDelete.id

      for (let i = 0; i < this.form.products.length; i++) {
        if (this.form.products[i].id === productId) {
          if (this.form.products[i].quantity > 1) {
            this.form.products[i].quantity -= 1
          } else {
            this.form.products.splice(index, 1)
          }
        }
      }
    },
    changeEndDay(day) {
      let date = new Date()
      if (this.form.credit_months) {
        date = this.getDateAfterMonths(Number(this.form.credit_months))
      }
      date.setDate(day)
      const eDate = date.toLocaleDateString()
      if (eDate.search('/') > 0) {
        this.form.end_date = `${eDate.split('/')[2]}-${eDate
          .split('/')[0]
          .padStart(2, '0')}-${eDate.split('/')[1].padStart(2, '0')}`
      } else {
        this.form.end_date = eDate.split('.').reverse().join('-')
      }
    },
    getDateAfterMonths(months) {
      const currentDate = new Date() // Get the current date
      let year = currentDate.getFullYear()
      let month = currentDate.getMonth()
      month += months
      while (month > 11) {
        month -= 12
        year++
      }
      const lastDay = new Date(year, month + 1, 0).getDate()
      const newDate = new Date(year, month, lastDay)
      return newDate
    },
    calcEndDate() {
      const date = this.getDateAfterMonths(Number(this.form.credit_months))
      const dM = this.form.credit_payment_day
        ? this.form.credit_payment_day
        : 15
      date.setDate(dM)
      return date.toLocaleDateString()
    },
    getProduct(id) {
      let prodObj = null
      if (id && this.products && this.products.data.length) {
        this.products.data.forEach(product => {
          if (product.id === id) {
            prodObj = product
          }
        })
      }
      return prodObj
    },
    calcLimitCredit() {
      let limit = ''
      if (
        this.clientReport
        && this.form.credit_months
        && this.clientReport.limit_with_co_borrower
      ) {
        limit = this.form.credit_months * this.clientReport.limit_with_co_borrower
      } else if (
        this.clientReport
        && this.form.credit_months
        && this.clientReport.limit
      ) {
        limit = this.form.credit_months * this.clientReport.limit
      } else if (this.clientReport && this.form.credit_months) {
        limit = Number(this.oneLimit)
      }
      this.totalLimit = limit
      if (limit) {
        return `(Limit: ${this.$options.filters.formatPrice(limit)} so'm)`
      }
      return ''
    },
    getTotalProduct() {
      let total = 0
      this.form.products.forEach(el => {
        total += el.quantity * el.price
      })
      return total
    },
    addProductShow() {
      this.show_add_product = true
      if (
        this.form.products[this.form.products.length - 1].barcode
        && this.form.products[this.form.products.length - 1].barcode.length == 12
      ) {
        this.addProduct()
      }
      setTimeout(() => {
        this.$refs.barcode[this.form.products.length - 1].focus()
      }, 100)
    },
    getProductByBarcode(barcode, index) {
      if (barcode && barcode.length === 12) {
        this.barcodeInputLoading = true
        const foundProduct = this.form.products.find(
          el => el.barcode == barcode,
        )
        let current_quantity = 0
        if (foundProduct && foundProduct.id) {
          current_quantity = foundProduct.quantity
        }
        this.getWarehouseProducts({ barcode, current_quantity })
          .then(res => {
            const product = this.form.products[index]
            const hasProduct = this.form.products.find(
              el => el.id === res.data.id,
            )
            const hasProductIndex = hasProduct
              ? this.form.products.indexOf(hasProduct)
              : null
            if (
              hasProduct
              && this.form.products.length > 1
              && this.form.products.length - 1 == index
            ) {
              this.form.products[hasProductIndex].quantity += 1
            } else if (product && this.form.products.length - 1 == index) {
              product.id = res.data.id
              product.category_id = res.data.category_id
              product.name = res.data.category.name_uz
              product.price = res.data.price
              product.imei_1 = res.data.imei_1
              product.imei_2 = res.data.imei_2
            }
            if (this.form.products.length - 1 == index) {
              this.addProduct()
            }
            this.$refs.barcode[0].focus()
          })
          .finally(() => {
            this.barcodeInputLoading = false
          })
      } else if (barcode && barcode.length > 12) {
        this.form.products[index].barcode = Number(
          String(barcode).slice(0, 12),
        )
      }
    },

    clearProductsArray() {
      if (this.form.products.length > 1) {
        this.form.products = this.form.products.filter(el => el.id != null)
      }
    },
    openICloudModal(product) {
      this.attachForm.contract_product_id = product.category_id
      this.icloudModal = true
    },
    closeCloudModal(iCloudInfo) {
      this.icloudModal = false
      this.form.products.forEach(el => {
        if (el.category_id == this.attachForm.contract_product_id) {
          el.icloud_id = iCloudInfo.id
        }
      })
    },
    ...mapActions({
      confirmSms: 'resource/confirmSms',
      sendSmsAction: 'resource/sendSms',
      store: 'contract/store',
      update: 'contract/update',
      getProductsAction: 'product/index',
      getItemsAction: 'productCategory/tree',
      getWarehouseProducts: 'productCategory/warehouseProductsByBarcode',
      fileUpload: 'client/fileUpload',
      fetchSettings: 'setting/index',
      calculateCreditAmount: 'resource/calculateCreditAmount',
      calculatediscountAmount: 'resource/calculatediscountAmount',
    }),
  },
}
</script>

<style scoped></style>
