<template>
  <div>
    <template v-if="clientReport">
      <h4 class="text-center">
        Mijoz natijalari
      </h4>
      <response-show
        :form="form"
        type="clientReport"
        :client-report="clientReport"
      />
    </template>
    <template v-if="client2Report">
      <hr>
      <h4 class="text-center">
        Birgalikda qarz oluvchi natijalari
      </h4>
      <response-show
        :form="form"
        type="client2Report"
        :client-report="client2Report"
      />
    </template>

    <div
      v-if="clientReport || client2Report"
      class="mt-2 text-right"
    >
      <span class="fs22 font-weight-bolder mr-2">
        Yakuniy natija:
      </span>
      <span
        v-if="clientReport.contract_type==1"
        class="float-right"
      >
        <template v-if="clientReport.result">
          <span class="fs22 font-weight-bolder text-success">Qoniqarli</span>
          <feather-icon
            class="ml-1"
            color="green"
            icon="CheckCircleIcon"
            size="20"
          />
        </template>
        <template v-else>
          <span class="fs22 font-weight-bolder text-danger">Qoniqarsiz</span>
          <feather-icon
            color="red"
            icon="XIcon"
            size="20"
          />
        </template>
      </span>
      <span
        v-if="clientReport.contract_type==2"
        class="float-right"
      >
        <template v-if="clientReport.result_with_co_borrower">
          <span class="fs22 font-weight-bolder text-success">Qoniqarli</span>
          <feather-icon
            class="ml-1"
            color="green"
            icon="CheckCircleIcon"
            size="20"
          />
        </template>
        <template v-else>
          <span class="fs22 font-weight-bolder text-danger">Qoniqarsiz</span>
          <feather-icon
            color="red"
            icon="XIcon"
            size="20"
          />
        </template>
      </span>
      <div class="mt-1 fs22">
        <span class="fs22 font-weight-bolder mr-2">
          Umumiy limit:
        </span>
        <span
          v-if="clientReport.contract_type==1"
          class="float-right"
        >
          <span class="float-right">
            <span class="font-weight-bolder text-success"> {{ clientReport.limit|formatPrice }} so'm</span>
          </span>
        </span>
        <span
          v-if="clientReport.contract_type==2"
          class="float-right"
        >
          <span class="float-right">
            <span class="font-weight-bolder text-success"> {{ clientReport.limit_with_co_borrower|formatPrice }} so'm</span>
          </span>
        </span>
      </div>
    </div>

    <div
      v-if="clientReport&&clientReport.is_exception_situation"
      class="mt-2 text-center"
    >
      <span class="fs22 text-warning font-weight-bolder">
        Istisno sifatida tasdiqlandi
      </span>
    </div>
    <div
      v-if="$route.query.step !=='form'"
      class="text-right mt-3"
    >
      <b-button
        v-if="isResultSuccess"
        variant="primary"
        class="btn-tour-skip"
        @click="createOrder"
      >
        <span class="mr-25 align-middle">Shartnoma yaratish</span>
      </b-button>
      <b-button
        v-else
        variant="warning"
        class="btn-tour-skip"
        @click="sendSmsException"
      >
        <!-- :disabled="disabledCase" -->
        <span class="mr-25 align-middle">Istisno sifatida yaratish</span>
      </b-button>
    </div>
    <ConfirmCode
      v-if="openModal"
      :visible="openModal"
      @close="openModal=false"
      @save="confirmForException"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { showToast } from '@/utils/toast'
import ResponseShow from './response_show.vue'
import ConfirmCode from './confirm_code.vue'

export default {
  components: { ResponseShow, ConfirmCode },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    selfShopInfo: {
      type: Object,
      default: () => null,
    },
    hasException: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openModal: false,
      expClientReportId: null,
      expContractTypeId: null,
    }
  },
  computed: {
    ...mapGetters({
      clientReport: 'resource/CLIENT_REPORT',
      client2Report: 'resource/CLIENT2_REPORT',
      clientsReport: 'resource/CLIENTS_REPORT',
    }),
    isSelfShopExpSituation() {
      return !((this.selfShopInfo && this.selfShopInfo.selfShopReportForClient && this.selfShopInfo.selfShopReportForClient.result_data.result_from_self_shop == 0)
       || (this.selfShopInfo && this.selfShopInfo.selfShopReportForClient2 && this.selfShopInfo.selfShopReportForClient2.result_data.result_from_self_shop == 0))
    },
    isResultSuccess() {
      if (this.clientReport && this.clientReport.contract_type == 1) {
        return !!(this.clientReport && this.clientReport.result)
      }
      if (this.clientReport && this.clientReport.contract_type == 2) return !!(this.clientReport && this.clientReport.result_with_co_borrower)
    },
    disabledCase() {
      return this.isSelfShopExpSituation && !(this.clientReport && this.clientReport.scoring_report_id)
    },
  },
  methods: {
    ...mapActions({
      confirmForExceptionSituations: 'resource/confirmForExceptionSituations',
      sendForExceptionSituations: 'resource/sendForExceptionSituations',
      clientReports: 'resource/clientReports',
    }),
    sendSmsException() {
      if (this.disabledCase) {
        this.clientReports({ client_id: this.$parent.form.client_id }).then(res => {
          this.expClientReportId = res.data.data[0].id
          this.expContractTypeId = res.data.data[0].contract_type
          this.sendForExceptionSituations()
          this.openModal = true
        })
      } else {
        this.sendForExceptionSituations()
        this.openModal = true
      }
    },
    createOrder(isExcep) {
      this.$emit('createOrder', { isExcep })
    },
    async confirmForException(code) {
      console.log('this.hasException', this.hasException)
      const expData = {
        // exception_situation_id: this.hasException ? 2 : 1,
        exception_situation_id: 1,
        code,
      }
      if (this.hasException) {
        expData.exception_situation_id = 2
      }
      if (this.$parent.form.contract_type) {
        expData.contract_type = this.$parent.form.contract_type
      }
      if (this.clientReport) {
        expData.client_report_id = this.clientReport.id
        expData.contract_type = this.clientReport.contract_type
      } else if (this.expClientReportId) {
        expData.client_report_id = this.expClientReportId
        expData.contract_type = this.expContractTypeId
      }

      if (this.selfShopInfo) {
        expData.self_shop_report_for_client_id = this.selfShopInfo.selfShopReportForClient.id
        if (this.selfShopInfo.selfShopReportForClient2) {
          expData.self_shop_report_for_client2_id = this.selfShopInfo.selfShopReportForClient2.id
        }
      }
      await this.confirmForExceptionSituations(expData)
        .then(res => {
          if (res.success && res.data) {
            this.openModal = false
            if (this.expClientReportId) {
              this.createOrder(true)
            } else if (!this.isSelfShopExpSituation && !this.clientReport) {
              this.$emit('createExceptionOrder', expData)
            } else {
              this.createOrder(true)
            }
          }
        })
        .catch(err => {
          if (err && err.status == 422) {
            // showToast('danger', err.data.message, 'XIcon')
          } else if (err && err.status == 501) {
            this.openModal = false
          }
        })
    },
  },
}
</script>
